// src/storage/StorageManager.js

class StorageManager {
    static async get(key) {
      // Simulate async storage access if needed
      return localStorage.getItem(key);
    }
  
    static async set(key, value) {
      // Simulate async storage access if needed
      localStorage.setItem(key, value);
    }
  
    static async remove(key) {
      // Simulate async storage access if needed
      localStorage.removeItem(key);
    }
  }
  
  export default StorageManager;
  