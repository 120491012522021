import React from "react";
import img1 from "../../assets/images/paint3.jpg";
import "../../../src/index.css";

function HeroSection() {
  return (
    <>
      <div className="md:py-[12px] py-[12px] md:px-[32px] px-[16px]">
        <div
          className="w-[100%] md:h-[60vh] h-[60vh] bg-cover rounded-3xl flex md:flex-row flex-col items-end justify-between px-6 py-14 "
          style={{ backgroundImage: `url(${img1})` }}
        >
          <div className="md:w-[55%]">
            <h1 className="text-white md:text-5xl text-3xl MediumFont ">
              Your One-Stop Shop for Quality Construction Materials
            </h1>
          </div>
          <div>
            <button className="bg-[#004D00] py-2 px-6 rounded-lg text-white RegularFont text-lg">
              Shop Now
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default HeroSection;
