import React, { useEffect } from 'react';
import Navbar from '../../components/Navbar/Navbar';
import OrderDelivery from '../../components/OrderDelivery/OrderDelivery';
import Footer from '../../components/Footer/Footer';

function OrderOPtions() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
    <Navbar />
    <OrderDelivery />
    <Footer />
      
    </>
  )
}

export default OrderOPtions
