import React, { useRef, useState, useEffect } from "react";
import { ENDPOINTS } from "../../api/apiRoutes";
import flagicon from "../../assets/images/flagicon.png";
import xclose from "../../assets/images/x-close.png";
import upload from "../../assets/images/upload-cloud.png";
import "../../index.css";
import API from "../../api/apiService";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function EditCategoriesModal({ category, handleCloseModal }) {
  const fileInputRef = useRef(null);
  const [categoryName, setCategoryName] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);

  useEffect(() => {
    if (category) {
      setCategoryName(category.name || "");
      setPreviewImage(category.image || null);
    }
  }, [category]);

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      setPreviewImage(URL.createObjectURL(file));
    }
  };

  const handleCategoryNameChange = (event) => {
    setCategoryName(event.target.value);
  };

  const handleAddCategory = async () => {
    if (!categoryName || (!selectedFile && !previewImage)) {
      alert("Please provide a category name and an image.");
      return;
    }

    setIsLoading(true);

    try {
      const formData = new FormData();
      formData.append("name", categoryName);
      formData.append("isActive", true);
      if (selectedFile) {
        formData.append("image", selectedFile);
      }

      const endpoint = ENDPOINTS.UPDATE_CATEGORY.replace(":id", category._id);

      const response = await API.put(endpoint, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      console.log("Category Updated:", response);
      toast.success("Category updated successfully!");
      handleCloseModal();
    } catch (error) {
      console.error("Error updating category:", error);
      toast.error("There was an error updating the category.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="fixed inset-0 bg-gray-300 bg-opacity-50 backdrop-blur-sm z-40"></div>

      <div className="fixed inset-0 flex items-center justify-center z-50">
        <div className="md:p-6 p-4 border-2 rounded-md md:w-[30%] w-full bg-white">
          <div className="flex justify-between">
            <div className="p-2 border border-gray-300 rounded-md">
              <img src={flagicon} alt="Flag Icon" />
            </div>
            <div>
              <img
                src={xclose}
                alt="Close Icon"
                onClick={handleCloseModal}
                className="cursor-pointer"
              />
            </div>
          </div>

          <div className="mt-6 flex flex-col gap-5">
            <div>
              <h1 className="text-[#101828] inter-Semi text-[18px]">
                Edit Category
              </h1>
            </div>

            <div className="flex flex-col gap-2">
              <p className="text-[#344054] MediumFont text-sm">Name</p>
              <input
                type="text"
                placeholder="Enter Category Name"
                className="border border-gray-300 outline-none p-2 rounded-md"
                value={categoryName}
                onChange={handleCategoryNameChange}
              />
            </div>

            <div className="flex flex-col gap-2">
              <p className="text-[#344054] MediumFont text-sm">
                Category Image
              </p>
              <div
                className="flex flex-col items-center justify-center gap-4 border border-gray-300 rounded-md p-4 cursor-pointer"
                onClick={handleUploadClick}
              >
                <div className="bg-[#F2F4F7] rounded-full p-2">
                  {previewImage ? (
                    <img
                      src={previewImage}
                      alt="Selected Category"
                      className="w-20 h-20 object-cover rounded-md"
                    />
                  ) : (
                    <img src={upload} alt="Upload Icon" />
                  )}
                </div>
                <div className="w-[300px]">
                  <p className="text-[#475467] NormalFont text-sm text-center ">
                    <span className="text-[#003E00] inter-Semi text-sm ">
                      Click to upload{" "}
                    </span>
                    or drag and drop SVG, PNG, JPG or GIF (max. 800x400px)
                  </p>
                </div>
                <input
                  type="file"
                  ref={fileInputRef}
                  className="hidden"
                  onChange={handleFileChange}
                />
              </div>
            </div>

            <div className="flex gap-4 items-center justify-center">
              <div>
                <button
                  onClick={handleCloseModal}
                  className="text-[#003E00] text-sm inter-Semi bg-[#F9FFF5] py-2 md:px-8 px-6 rounded-lg"
                >
                  Cancel
                </button>
              </div>
              <div>
                <button
                  onClick={handleAddCategory}
                  className="bg-[#003E00] text-sm inter-Semi text-white py-2 px-10 rounded-lg"
                  disabled={isLoading}
                >
                  {isLoading ? "Edit..." : "Edit Category"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditCategoriesModal;
