import React, { useEffect } from 'react';
import Navbar from '../../components/Navbar/Navbar';
import HeroSection from '../../components/HeroSection/HeroSection'
import FoodSection from '../../components/FoodSection/FoodSection';
import OrderSearch from '../../components/OrderSearch/OrderSearch';
import Footer from '../../components/Footer/Footer';
import Landingpage from '../../components/LandingPage/Landingpage';

function Order_Placement() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); 
  return (
    <>
    <Navbar />
    {/* <HeroSection /> */}
    <Landingpage />
    <FoodSection />
    <OrderSearch />
    <Footer />
      
    </>
  )
}

export default Order_Placement
