import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; 

function ProfileInformation() {
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState(""); 
  const [isButtonDisabled, setIsButtonDisabled] = useState(true); 

  useEffect(() => {
    // Load data from localStorage on mount
    const storedUserName = JSON.parse(localStorage.getItem("userName"));
    const storedEmail = localStorage.getItem("email");
    const storedAddress = localStorage.getItem("address");

    if (storedUserName) setUserName(storedUserName);
    if (storedEmail) setEmail(storedEmail);
    if (storedAddress) setAddress(storedAddress);
  }, []);

  const handleAddressChange = (e) => {
    setAddress(e.target.value);
    setIsButtonDisabled(e.target.value.trim() === ""); 
  };

  const handleSave = () => {
    localStorage.setItem("userName", JSON.stringify(userName));
    localStorage.setItem("email", email);
    localStorage.setItem("address", address);
    toast.success("Information saved successfully!");
  };

  return (
    <div className="flex items-center justify-center">
      <div className="md:w-[50%] w-full">
        <div className="flex flex-col gap-2">
          <label htmlFor="name" className="font-medium md:text-2xl text-xl">
            Name
          </label>
          <input
            type="text"
            value={userName}
            readOnly
            className="border border-gray-300 outline-none rounded-lg p-3 text-lg"
          />
        </div>
        <div className="flex flex-col gap-2 mt-5">
          <label htmlFor="email" className="font-medium md:text-2xl text-xl">
            Email Address
          </label>
          <input
            type="text"
            value={email}
            readOnly
            className="border border-gray-300 outline-none rounded-lg p-3 text-lg"
          />
        </div>
        <div className="flex flex-col gap-2 mt-5">
          <label htmlFor="address" className="font-medium md:text-2xl text-xl">
            Address
          </label>
          <textarea
            name="address"
            id="address"
            rows="6"
            cols="50"
            value={address}
            onChange={handleAddressChange}
            className="p-4 border border-gray-300 rounded-md text-lg focus:outline-none"
          ></textarea>
        </div>
        <div className="mt-4 items-center justify-center flex">
          <button
            onClick={handleSave}
            disabled={isButtonDisabled}
            className={`px-6 py-2 font-semibold text-xl rounded-md ${
              isButtonDisabled ? "bg-gray-300 cursor-not-allowed" : "bg-[#ACE03A]"
            }`}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
}

export default ProfileInformation;
