import React, { useState } from "react";
import Navbar from "../../components/Navbar/Navbar";
import Landingpage from "../../components/LandingPage/Landingpage";
import FoodSection from "../../components/FoodSection/FoodSection";
import Snacks from "../../components/SnacksSection/Snacks";
import Collection from "../../components/ExploreCollection/Collection";
import Footer from "../../components/Footer/Footer";
import AppBanner from "../../components/AppBanner/AppBanner";
import WishlistSlider from "../../components/WishlistSlider/WishlistSlider";

export default function Home() {
  const [isWishlistOpen, setIsWishlistOpen] = useState(false);

  const handleWishlistOpen = () => setIsWishlistOpen(true);
  const handleWishlistClose = () => setIsWishlistOpen(false);
  return (
    <>
      <Navbar />
      <Landingpage />
      <FoodSection />
      <Snacks />
      <AppBanner />
      {/* <Collection /> */}
      <Footer onWishlistClick={handleWishlistOpen} />
      <WishlistSlider isOpen={isWishlistOpen} onClose={handleWishlistClose} />
    </>
  );
}
