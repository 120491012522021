import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isVerified: false,
  isPending: false,
  user: null,
  verificationError: null,
};

const loadStateFromLocalStorage = () => {
  try {
    const serializedState = localStorage.getItem("verificationState");
    if (serializedState === null) {
      return initialState;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return initialState;
  }
};

const initialStateFromLocalStorage = loadStateFromLocalStorage();

const verificationSlice = createSlice({
  name: "verification",
  initialState: initialStateFromLocalStorage,
  reducers: {
    startVerification(state) {
      state.isPending = true;
      state.verificationError = null; // Clear previous errors
    },
    verifySuccess(state, action) {
      state.isVerified = true;
      state.isPending = false;
      state.user = action.payload.user; // Store user info
      localStorage.setItem("verificationState", JSON.stringify(state));
    },
    verifyFailure(state, action) {
      state.isVerified = false;
      state.isPending = false;
      state.verificationError = action.payload; // Store the error message
      localStorage.setItem("verificationState", JSON.stringify(state));
    },
    resetVerification(state) {
      state.isVerified = false;
      state.isPending = false;
      state.user = null;
      state.verificationError = null;
      localStorage.removeItem("verificationState");
    },
  },
});

export const { startVerification, verifySuccess, verifyFailure, resetVerification } = verificationSlice.actions;

export default verificationSlice.reducer;
