import { configureStore } from "@reduxjs/toolkit";
import productReducer from "./slices/productSlice";
import categoriesReducer from "./slices/categoriesSlice";
import inventoryReducer from "./slices/inventorySlice";
import cartReducer from "./slices/cartSlice";
import wishlistReducer from "./slices/wishlistSlice";
import loginReducer from "./slices/loginSlice";
import orderReducer from "./slices/orderSlice";
import bannerReducer from "./slices/bannerSlice";
import verificationReducer from "./slices/verificationSlice";

const store = configureStore({
  reducer: {
    product: productReducer,
    categories: categoriesReducer,
    inventory: inventoryReducer,
    cart: cartReducer,
    wishlist: wishlistReducer,
    login: loginReducer,
    orders: orderReducer,
    banner: bannerReducer,
    verification: verificationReducer,
  },
});

export default store;
