import React, { useEffect, useState } from 'react';
import img1 from "../../assets/images/greenvegi.png";
import ProfileInformation from '../../components/ProfileInformation/ProfileInformation';
import OrderTracking from '../../components/OrderTracking/OrderTracking';
import { IoArrowBackCircleOutline } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';



function OrderTrack() {
  const navigate = useNavigate();
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

  const [activeTab, setActiveTab] = useState("profile");

  return (
    <>
      {/* Background Section */}
      <div
        className="w-full md:h-[50vh] h-[30vh]" 
        style={{
          backgroundImage: `url(${img1})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
       <div className="p-4">
            <IoArrowBackCircleOutline
              size={50}
              color="black"
              onClick={() => navigate("/")}
              className="cursor-pointer"
            />
          </div>

      </div>

      {/* Tab Menu */}
      <div className="flex items-center justify-center md:gap-12 gap-5">
        <div
          className={`p-2 cursor-pointer ${
            activeTab === "profile" ? "border-b-4 border-b-[#ACE03A]" : ""
          }`}
          onClick={() => setActiveTab("profile")}
        >
          <p className="font-medium md:text-3xl text-lg">Profile Information</p>
        </div>
        <div
          className={`p-2 cursor-pointer ${
            activeTab === "orders" ? "border-b-4 border-b-[#ACE03A]" : ""
          }`}
          onClick={() => setActiveTab("orders")}
        >
          <p className="font-medium md:text-3xl text-lg">Orders</p>
        </div>
      </div>

      {/* Tab Content */}
      <div className="p-6">
        {activeTab === "profile" && <ProfileInformation />}
        {activeTab === "orders" && <OrderTracking />}
      </div>
    </>
  );
}

export default OrderTrack;
