import React, { useRef, useEffect } from "react";
import Slider from "react-slick";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setCategories, setLoading } from "../../redux/slices/categoriesSlice";
import API from "../../api/apiService";
import { ENDPOINTS } from "../../api/apiRoutes";
import icon1 from "../../assets/images/Chevron left.png";
import icon2 from "../../assets/images/Chevron right.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./FoodSection.css";
import "../../index.css";

function FoodSection() {
  const sliderRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { items: categories, loading } = useSelector(
    (state) => state.categories
  );

  const handleSlideClick = (param) => {
    navigate(`/orderPlacement?name=${param.name}&id=${param.id}`);
  };

  const settings = {
    infinite: true,
    speed: 1000,
    slidesToShow: 10,
    slidesToScroll: 3,
    initialSlide: 0,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    const fetchCategories = async () => {
      dispatch(setLoading(true));
      try {
        const response = await API.get(ENDPOINTS.GET_CATEGORIES);
        dispatch(setCategories(response?.categories || []));
      } catch (error) {
        console.error("Error fetching categories:", error);
        dispatch(setCategories([]));
      } finally {
        dispatch(setLoading(false));
      }
    };

    fetchCategories();
  }, [dispatch]);

  const handlePrevClick = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  const handleNextClick = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  return (
    <div className="md:py-[0px] py-[10px] md:px-[32px] px-[16px]">
      <div className="flex justify-between">
        <div>
          <p className="font-semibold md:text-[32px] text-xl text-[#253D4E]">
            Explore Categories
          </p>
        </div>
        <div className="flex md:gap-[16px] gap-[4px]">
          <div className="bg-[#ACE03A] w-8 h-8 flex items-center justify-center rounded-full">
            <img
              src={icon1}
              alt="Previous"
              onClick={handlePrevClick}
              className="cursor-pointer"
            />
          </div>
          <div className="bg-[#ACE03A] w-8 h-8 flex items-center justify-center rounded-full">
            <img
              src={icon2}
              alt="Next"
              onClick={handleNextClick}
              className="cursor-pointer"
            />
          </div>
        </div>
      </div>
      <div className="mt-4">
        <Slider ref={sliderRef} {...settings} className="food-slider">
          {categories.length > 0
            ? categories.map((item) => (
                <div
                  key={item._id}
                  className="food-item"
                  onClick={() =>
                    handleSlideClick({ name: item.name, id: item._id })
                  }
                >
                  <img
                    src={item.image}
                    alt="image"
                    className="food-image w-[80px] h-[70px]"
                  />
                  <p className="text-[#253D4E] md:font-bold font-semibold md:text-base text-sm text-center">
                    {item.name.length > 18
                      ? item.name.slice(0, 18) + ".."
                      : item.name}
                  </p>
                </div>
              ))
            : null}
        </Slider>
      </div>
    </div>
  );
}

export default FoodSection;
