import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../api/apiService";
import { ENDPOINTS } from "../../api/apiRoutes";

export const fetchBannersByType = createAsyncThunk(
  "banners/fetchBannersByType",
  async (bannerType, { rejectWithValue }) => {
    try {
      const response = await API.get(
        `${ENDPOINTS.GET_BANNERBYTYPE}/${bannerType}`
      );
      return { bannerType, banners: response };
    } catch (error) {
      return rejectWithValue(error.response ? error.response : error.message);
    }
  }
);

const initialState = {
  loading: false,
  banners: {
    topBanner: [],
    middleBanner: [],
  },
  error: null,
};

const bannerSlice = createSlice({
  name: "banners",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBannersByType.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchBannersByType.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload.bannerType === "Top Banner") {
          state.banners.topBanner.push(action.payload.banners.banners);
        } else if (action.payload.bannerType === "Middle Banner") {
          state.banners.middleBanner.push(action.payload.banners.banners);
        }
        state.error = null;
      })
      .addCase(fetchBannersByType.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || action.error.message;
      });
  },
});

export default bannerSlice.reducer;
