import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import axios from "axios";
import { ENDPOINTS } from "../../api/apiRoutes";
import API from "../../api/apiService";
import { useNavigate } from "react-router-dom";

function OrderTracking() {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const ordersPerPage = 10;
  const navigate=useNavigate();

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const userId = localStorage.getItem("_id");
        if (!userId) {
          throw new Error("Please Enter your details to Login.");
        }
        const res = await API.get(`${ENDPOINTS.GET_USERORDER}/${userId}`);
        setOrders(res.orders);
        setLoading(false);
      } catch (err) {
        setError(err.message || "Failed to fetch orders.");
        setLoading(false);
      }
    };

    fetchOrders();
  }, []);

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

  const displayedOrders = orders.slice(
    currentPage * ordersPerPage,
    (currentPage + 1) * ordersPerPage
  );

  if (loading) return <div>Loading...</div>;
  if (error) return <div className="text-2xl">No oder Found!</div>;

  return (
    <div className="w-[80%] mx-auto mt-10">
      <div className="overflow-x-auto">
        <table className="table-auto w-full border-collapse">
          <thead>
            <tr className="border-b border-gray-300">
              <th className="border border-gray-300 px-4 py-2 text-left">
                Date
              </th>
              <th className="border border-gray-300 px-4 py-2 text-left">
                Order-ID
              </th>
              <th className="border border-gray-300 px-4 py-2 text-left">
                Total Amount
              </th>
              <th className="border border-gray-300 px-4 py-2 text-left">
                Status
              </th>
              <th className="border border-gray-300 px-4 py-2 text-left"></th>
            </tr>
          </thead>
          <tbody>
            {displayedOrders.map((order, index) => (
              <tr key={index} className="border-b border-gray-300">
                <td className="border border-gray-300 px-4 py-2">
                  {" "}
                  {new Date(order.createdAt).toLocaleDateString()}
                </td>
                <td className="border border-gray-300 px-4 py-2">
                  {order._id}
                </td>
                <td className="border border-gray-300 px-4 py-2">
                  {order.totalPrice}
                </td>
                <td className="border border-gray-300 px-4 py-2">
                  {order.status}
                </td>
                <td className="border border-gray-300 px-4 py-2">
                  <button className="bg-[#ACE03A] py-1 px-4 rounded-md"  onClick={() => navigate(`/orderDetails/${order?._id}`, { state: { id: order._id } })}>View</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <ReactPaginate
        previousLabel={"Previous"}
        nextLabel={"Next"}
        pageCount={Math.ceil(orders.length / ordersPerPage)}
        onPageChange={handlePageClick}
        containerClassName={"pagination"}
        pageClassName={"page-item"}
        pageLinkClassName={"page-link"}
        previousClassName={"page-item"}
        previousLinkClassName={"page-link"}
        nextClassName={"page-item"}
        nextLinkClassName={"page-link"}
        activeClassName={"active"}
      />
    </div>
  );
}

export default OrderTracking;
