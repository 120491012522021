// src/pages/Payment.jsx
import React, { useEffect, useState } from "react";

const Payment = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [paymentMethod, setPaymentMethod] = useState("creditCard");

  const handlePaymentMethodChange = (e) => {
    setPaymentMethod(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    alert("Payment successful! Thank you for your purchase.");
  };

  return (
    <div className="min-h-screen bg-gray-100 py-8 px-4">
      <div className="max-w-4xl mx-auto bg-white shadow-md rounded-md p-6">
        <h1 className="text-2xl font-bold text-center mb-6">Payment Details</h1>
        <form onSubmit={handleSubmit}>
          {/* Billing Details */}
          <div className="mb-6">
            <h2 className="text-lg font-semibold mb-4">Billing Details</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <input
                type="text"
                placeholder="First Name"
                className="border rounded-md px-4 py-2 focus:outline-none focus:ring focus:ring-blue-200"
                required
              />
              <input
                type="text"
                placeholder="Last Name"
                className="border rounded-md px-4 py-2 focus:outline-none focus:ring focus:ring-blue-200"
                required
              />
              <input
                type="email"
                placeholder="Email Address"
                className="border rounded-md px-4 py-2 focus:outline-none focus:ring focus:ring-blue-200"
                required
              />
              <input
                type="tel"
                placeholder="Phone Number"
                className="border rounded-md px-4 py-2 focus:outline-none focus:ring focus:ring-blue-200"
                required
              />
              <input
                type="text"
                placeholder="Address"
                className="col-span-1 md:col-span-2 border rounded-md px-4 py-2 focus:outline-none focus:ring focus:ring-blue-200"
                required
              />
            </div>
          </div>

          {/* Payment Method */}
          <div className="mb-6">
            <h2 className="text-lg font-semibold mb-4">Payment Method</h2>
            <div className="flex flex-col space-y-2">
              <label className="flex items-center space-x-2">
                <input
                  type="radio"
                  value="creditCard"
                  checked={paymentMethod === "creditCard"}
                  onChange={handlePaymentMethodChange}
                  className="form-radio"
                />
                <span>Credit/Debit Card</span>
              </label>
              <label className="flex items-center space-x-2">
                <input
                  type="radio"
                  value="paypal"
                  checked={paymentMethod === "paypal"}
                  onChange={handlePaymentMethodChange}
                  className="form-radio"
                />
                <span>PayPal</span>
              </label>
              <label className="flex items-center space-x-2">
                <input
                  type="radio"
                  value="cashOnDelivery"
                  checked={paymentMethod === "cashOnDelivery"}
                  onChange={handlePaymentMethodChange}
                  className="form-radio"
                />
                <span>Cash on Delivery</span>
              </label>
            </div>
          </div>

          {/* Payment Details */}
          {paymentMethod === "creditCard" && (
            <div className="mb-6">
              <h2 className="text-lg font-semibold mb-4">Card Details</h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <input
                  type="text"
                  placeholder="Card Number"
                  className="border rounded-md px-4 py-2 focus:outline-none focus:ring focus:ring-blue-200"
                  required
                />
                <input
                  type="text"
                  placeholder="Card Holder Name"
                  className="border rounded-md px-4 py-2 focus:outline-none focus:ring focus:ring-blue-200"
                  required
                />
                <input
                  type="text"
                  placeholder="Expiry Date (MM/YY)"
                  className="border rounded-md px-4 py-2 focus:outline-none focus:ring focus:ring-blue-200"
                  required
                />
                <input
                  type="text"
                  placeholder="CVV"
                  className="border rounded-md px-4 py-2 focus:outline-none focus:ring focus:ring-blue-200"
                  required
                />
              </div>
            </div>
          )}

          {/* Order Summary */}
          <div className="mb-6">
            <h2 className="text-lg font-semibold mb-4">Order Summary</h2>
            <div className="border rounded-md p-4 bg-gray-50">
              <p className="flex justify-between">
                <span>Subtotal:</span>
                <span>€0.00</span>
              </p>
              <p className="flex justify-between">
                <span>Shipping:</span>
                <span>€0.00</span>
              </p>
              <p className="flex justify-between font-bold">
                <span>Total:</span>
                <span>€0.00</span>
              </p>
            </div>
          </div>

          {/* Submit Button */}
          <button
            type="submit"
            className="w-full bg-blue-500 text-white font-semibold py-2 rounded-md hover:bg-blue-600 transition"
          >
            Pay Now
          </button>
        </form>
      </div>
    </div>
  );
};

export default Payment;
