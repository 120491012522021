import React from 'react';
import Navbar from '../../../components/Navbar/Navbar';
import TotalInventroy from '../../../components/TotalInventory/TotalInventroy';
import RecentInventory from '../../../components/RecentInventory/RecentInventory';

function Inventory() {
  return (
    <>
      <TotalInventroy />
      <RecentInventory />

    </>
  )
}

export default Inventory
