// import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// import API from "../../api/apiService";
// import { ENDPOINTS } from "../../api/apiRoutes";

// const initialState = {
//   products: [],
//   filteredProducts: [],
//   searchQuery: "",
//   loading: false,
//   error: null,
// };

// export const fetchProducts = createAsyncThunk(
//   "products/fetchProducts",
//   async (_, { rejectWithValue }) => {
//     // try {
//     //   const response = await API.get(ENDPOINTS.GET_ALLPRODUCTS);
//     //   console.log("inv calllledllllllllllllllllllll")
//     //   return response;
//     // } catch (error) {
//     //   return rejectWithValue(error.message);
//     // }
//   }
// );

// const productSlice = createSlice({
//   name: "product",
//   initialState,
//   reducers: {
//     setSearchQuery(state, action) {
//       state.searchQuery = action.payload;
//       state.filteredProducts = state.products.filter((product) =>
//         product.name.toLowerCase().includes(action.payload.toLowerCase())
//       );
//     },
//   },
//   extraReducers: (builder) => {
//     builder
//       .addCase(fetchProducts.pending, (state) => {
//         state.loading = true;
//         state.error = null;
//       })
//       .addCase(fetchProducts.fulfilled, (state, action) => {
//         state.loading = false;
//         state.products = action.payload;
//         state.filteredProducts = action.payload;
//       })
//       .addCase(fetchProducts.rejected, (state, action) => {
//         state.loading = false;
//         state.error = action.payload;
//       });
//   },
// });

// export const { setSearchQuery } = productSlice.actions;

// export default productSlice.reducer;


import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../api/apiService";
import { ENDPOINTS } from "../../api/apiRoutes";

const initialState = {
  products: [],
  filteredProducts: [],
  searchQuery: "",
  loading: false,
  error: null,
};

export const fetchProducts = createAsyncThunk(
  "products/fetchProducts",
  async (_, { rejectWithValue }) => {
    // try {
    //   const response = await API.get(ENDPOINTS.GET_ALLPRODUCTS);
    //   console.log("API response:", response);  // Log to check the response format
    //   return response;  // Adjust if response is nested (e.g., return response.data if needed)
    // } catch (error) {
    //   return rejectWithValue(error.message);
    // }
  }
);

const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    setSearchQuery(state, action) {
      state.searchQuery = action.payload;
      // Ensure products is an array before filtering
      state.filteredProducts = Array.isArray(state.products) 
        ? state.products.filter((product) =>
            product.name.toLowerCase().includes(action.payload.toLowerCase())
          )
        : [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProducts.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchProducts.fulfilled, (state, action) => {
        state.loading = false;
        
        // Ensure the response payload is an array or handle it accordingly
        state.products = Array.isArray(action.payload) ? action.payload : [];
        state.filteredProducts = state.products;  // Copy products to filteredProducts initially
      })
      .addCase(fetchProducts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { setSearchQuery } = productSlice.actions;

export default productSlice.reducer;
