import React from "react";
import img1 from "../../assets/images/googleplay.png";
import img2 from "../../assets/images/appstore.png";
import img3 from "../../assets/images/mobileapp.png";
import { Link } from "react-router-dom";

function AppBanner() {
  return (
    <>
      <div className="bg-[#C5EAD9C7] flex  md:flex-row flex-col p-10 items-center justify-center gap-8 mt-10">
        <div className="flex flex-col md:w-[30vw] w-full md:gap-12 gap-6">
          <div className="flex flex-col gap-6">
            <h1 className="md:text-5xl text-2xl text-[#253D4E] font-bold">
              Shop Faster With Apna Dokan App
            </h1>
            <p>Available on both IOS & Android</p>
          </div>

          <div className="flex md:flex-row flex-col">
            <Link to="https://play.google.com/store">
              <img
                src={img1}
                className="cursor-pointer"
                alt="Google Play Store"
              />
            </Link>
            <Link to="https://www.apple.com/app-store/">
              <img
                src={img2}
                className="cursor-pointer mt-[4px]"
                alt="Apple App Store"
              />
            </Link>
          </div>
        </div>

        <div>
          <img src={img3} />
        </div>
      </div>
    </>
  );
}

export default AppBanner;
