import React, { useState } from "react";
import flagicon from "../../assets/images/flagicon.png";
import xclose from "../../assets/images/x-close.png";
import upload from "../../assets/images/upload-cloud.png";
import "../../index.css";
import { ENDPOINTS } from "../../api/apiRoutes";
import API from "../../api/apiService";
import { toast } from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css'; 

function AddBannerModal({ handleCloseModal, handleBannerSubmit }) {
  const [selectedBanner, setSelectedBanner] = useState("Top Banner");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [isActive, setIsActive] = useState(true);
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleBannerChange = (event) => {
    setSelectedBanner(event.target.value);
  };

  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
  };

  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
  };

  const handleToggle = () => {
    setIsActive(!isActive);
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImage(file);
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("bannerType", selectedBanner);
      formData.append("startDate", startDate);
      formData.append("endDate", endDate);
      formData.append("isActive", isActive);
      formData.append("image", image);

      for (let [key, value] of formData.entries()) {
        console.log(`${key}: ${value}`);
      }

      const token = localStorage.getItem("token");

      const response = await API.post(ENDPOINTS.ADD_BANNER, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

        toast.success("Banner Added successfully!");
        console.log("Banner created successfully:", response);

        handleBannerSubmit(response);
        handleCloseModal();
      
    } catch (error) {
      toast.error("Error Adding banner. Please try again!");
      console.error("Error creating banner:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="fixed inset-0 bg-gray-300 bg-opacity-50 backdrop-blur-sm z-40"></div>

      <div className="fixed inset-0 flex items-center justify-center z-50">
        <div className="p-6 border-2 rounded-md w-auto bg-white">
          <div className="flex justify-between mb-4">
            <div className="p-2 border border-gray-300 rounded-md">
              <img src={flagicon} alt="Flag Icon" />
            </div>
            <div>
              <img
                src={xclose}
                alt="Close Icon"
                onClick={handleCloseModal}
                className="cursor-pointer"
              />
            </div>
          </div>

          <div className="mb-4">
            <label className="block text-sm text-[#344054] mb-2">
              Select Banner Type:
            </label>
            <select
              value={selectedBanner}
              onChange={handleBannerChange}
              className="border border-gray-300 rounded-md p-2 w-full"
            >
              <option value="Top Banner">Top Banner</option>
              <option value="Middle Banner">Middle Banner</option>
            </select>
          </div>

          <div className="mb-4">
            <label className="block text-sm text-[#344054] mb-2">
              Start Date:
            </label>
            <input
              type="date"
              value={startDate}
              onChange={handleStartDateChange}
              className="border border-gray-300 rounded-md p-2 w-full"
            />
          </div>

          <div className="mb-4">
            <label className="block text-sm text-[#344054] mb-2">
              End Date:
            </label>
            <input
              type="date"
              value={endDate}
              onChange={handleEndDateChange}
              className="border border-gray-300 rounded-md p-2 w-full"
            />
          </div>

          <div className="flex flex-col gap-2">
            <p className="text-[#344054] MediumFont text-sm">Banner Image</p>
            <div className="flex flex-col items-center justify-center gap-4 border border-gray-300 rounded-md p-4">
              <div className="bg-[#F2F4F7] rounded-full p-2">
                <img src={upload} alt="Upload Icon" />
              </div>
              <div className="w-[300px]">
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleImageUpload}
                  className="mt-2 w-full text-sm text-gray-500"
                />
              </div>
            </div>
          </div>

          <div className="mt-4 flex items-center justify-between">
            <p className="text-[#344054] MediumFont text-sm">Status:</p>
            <div className="flex items-center">
              <label className="relative inline-block w-10 h-6">
                <input
                  type="checkbox"
                  checked={isActive}
                  onChange={handleToggle}
                  className="sr-only peer"
                />
                <div className="w-full h-full bg-gray-300 rounded-full peer-checked:bg-[#003E00]"></div>
                <div className="absolute top-1 left-1 peer-checked:left-5 w-4 h-4 bg-white rounded-full transition-all"></div>
              </label>
              <span className="ml-3 text-sm">
                {isActive ? "Active" : "Not Active"}
              </span>
            </div>
          </div>

          <div className="flex gap-4 items-center justify-center mt-6">
            <button
              onClick={handleCloseModal}
              className="text-[#003E00] text-sm inter-Semi bg-[#F9FFF5] py-2 px-10 rounded-lg"
            >
              Cancel
            </button>
            <button
              onClick={handleSubmit}
              className={`bg-[#003E00] text-sm inter-Semi text-white py-2 px-10 rounded-lg ${
                loading && "cursor-not-allowed"
              }`}
              disabled={loading}
            >
              {loading ? "Adding..." : "Add Banner"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddBannerModal;
