import React from "react";
import { useNavigate } from "react-router-dom";

const Unauthorized = () => {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-200">
      <div className="bg-white shadow-lg rounded-lg p-10 max-w-md w-full text-center">
        <h1 className="text-4xl font-bold mb-4 text-gray-800">403</h1>
        <h2 className="text-2xl font-semibold mb-6 text-gray-700">
          Unauthorized Access
        </h2>
        <p className="mb-8 text-gray-600">
          Oops! You don’t have permission to view this page.
        </p>

        <div className="flex justify-around">
          <button
            onClick={() => navigate("/login")}
            className="px-6 py-2 bg-blue-500 text-white rounded-full hover:bg-blue-600 transition-all duration-300"
          >
            Login
          </button>
          <button
            onClick={() => navigate("/register")}
            className="px-6 py-2 bg-green-500 text-white rounded-full hover:bg-green-600 transition-all duration-300"
          >
            Sign Up
          </button>
        </div>
      </div>
    </div>
  );
};

export default Unauthorized;
