// import ReactPaginate from "react-paginate";
// import React, { useRef, useEffect, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { fetchProducts } from "../../redux/slices/productSlice";
// import {
//   addToWishlist,
//   removeFromWishlist,
// } from "../../redux/slices/wishlistSlice";
// import icon1 from "../../assets/images/Chevron left.png";
// import icon2 from "../../assets/images/Chevron right.png";
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import "./snacks.css";
// import "../../../src/index.css";
// import API from "../../api/apiService";
// import { ENDPOINTS } from "../../api/apiRoutes";
// import { Link } from "react-router-dom";
// import { FaRegHeart, FaHeart } from "react-icons/fa";
// import { addToCart } from "../../redux/slices/cartSlice";
// import { toast } from "react-toastify";
// import { PiShoppingCartLight } from "react-icons/pi";
// import { TailSpin } from "react-loader-spinner";
// import Landingpage from "../Bannercomp/Bannercomp";

// function Snacks() {
//   const { searchQuery } = useSelector((state) => state.product);
//   const wishlistItems = useSelector((state) => state.wishlist.items);
//   const cartItems = useSelector((state) => state.cart.cartItems);
//   const [categories, setCategories] = useState([]);
//   const [visibleCategoryIndex, setVisibleCategoryIndex] = useState(null);
//   const [count, setCount] = useState(1);
//   const sliderRefs = useRef([]);
//   const dispatch = useDispatch();
//   const [currentPage, setCurrentPage] = useState(1);
//   const categoriesPerPage = 5;
//   const [totalPages, setTotalPages] = useState(0);
//   const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     dispatch(fetchProducts());
//   }, [dispatch]);

//   const fetchCategories = async (page) => {
//     setLoading(true);
//     try {
//       const response = await API.get(
//         `${ENDPOINTS.GET_ALLPRODUCTS}?page=${page}&limit=${categoriesPerPage}`
//       );

//       const uniqueCategoriesMap = new Map();

//       response.inventoryItems.forEach((item) => {
//         const categoryId = item.category?._id;
//         const categoryName = item.category?.name;

//         if (categoryId) {
//           if (uniqueCategoriesMap.has(categoryId)) {
//             uniqueCategoriesMap.get(categoryId).products.push(item);
//           } else {
//             uniqueCategoriesMap.set(categoryId, {
//               _id: categoryId,
//               name: categoryName,
//               products: [item],
//             });
//           }
//         }
//       });

//       const uniqueCategories = Array.from(uniqueCategoriesMap.values());
//       setCategories(uniqueCategories);
//       setTotalPages(response.totalPages);
//       setLoading(false);
//     } catch (err) {
//       console.error("Error fetching categories:", err);
//     }
//   };

//   useEffect(() => {
//     fetchCategories(currentPage);
//   }, [currentPage]);

//   const filteredCategories = categories
//     .map((category) => ({
//       ...category,
//       products:
//         category.products?.filter((product) =>
//           product.name.toLowerCase().includes(searchQuery.toLowerCase())
//         ) || [],
//     }))
//     .filter((category) => category.products.length > 0);

//   const handleAddToCart = (product) => {
//     dispatch(
//       addToCart({ ...product, quantity: count, stock: product.quantity })
//     );
//     toast.success("Item added to cart successfully!");
//   };

//   const offset = (currentPage - 1) * categoriesPerPage;
//   const displayedCategories = filteredCategories.slice(
//     offset,
//     offset + categoriesPerPage
//   );

//   const handlePageClick = ({ selected }) => {
//     setCurrentPage(selected + 1);
//     window.scrollTo(0, 0);
//   };

//   const settings = {
//     infinite: false,
//     speed: 1000,
//     slidesToShow: 6,
//     slidesToScroll: 4,
//     initialSlide: 0,
//     arrows: false,
//     centerMode: false,
//     responsive: [
//       {
//         breakpoint: 1024,
//         settings: {
//           slidesToShow: 3,
//           slidesToScroll: 4,
//           infinite: false,
//           dots: false,
//         },
//       },
//       {
//         breakpoint: 600,
//         settings: {
//           slidesToShow: 2,
//           slidesToScroll: 1,
//           initialSlide: 2,
//           infinite: false,
//           dots: false,
//         },
//       },
//       {
//         breakpoint: 480,
//         settings: {
//           slidesToShow: 1,
//           slidesToScroll: 1,
//           infinite: false,
//           dots: false,
//         },
//       },
//     ],
//   };

//   const handlePrevClick = (index) => {
//     if (sliderRefs.current[index]) {
//       sliderRefs.current[index].slickPrev();
//     }
//   };

//   const handleNextClick = (index) => {
//     if (sliderRefs.current[index]) {
//       sliderRefs.current[index].slickNext();
//     }
//   };

//   const handleSeeMoreClick = (index) => {
//     setVisibleCategoryIndex(visibleCategoryIndex === index ? null : index);
//   };

//   const handleAddToWishlist = (e, product) => {
//     e.stopPropagation();
//     const isInWishlist = wishlistItems.some((item) => item._id === product._id);
//     if (isInWishlist) {
//       dispatch(removeFromWishlist(product._id));
//       toast.error("Item Removed from Wishlist");
//     } else {
//       dispatch(addToWishlist(product));
//       toast.success("Item Added to Wishlist");
//     }
//   };

//   return (
//     <div className="md:py-[24px] py-[12px] md:px-[32px] px-[16px] ">
//       {loading ? (
//         <div className="flex justify-center items-center h-[400px]">
//           <TailSpin
//             height="80"
//             width="80"
//             color="#ACE03A"
//             ariaLabel="tail-spin-loading"
//             radius="1"
//             visible={true}
//           />
//         </div>
//       ) : filteredCategories.length > 0 ? (
//         filteredCategories.map((category, index) => (
//           <div key={category._id} className="mb-8">
//             <div className="flex justify-between w-full items-center ">
//               <div className=" ">
//                 <p className="font-medium md:text-2xl text-base text-[#344054] ">
//                   {category.name}
//                 </p>
//               </div>
//               <div className="flex md:gap-[16px] gap-[8px]  ">
//                 <button
//                   className="md:text-xl text-sm text-[#000] MediumFont underline"
//                   onClick={() => handleSeeMoreClick(index)}
//                 >
//                   {visibleCategoryIndex === index ? "See Less" : "See All"}
//                 </button>
//                 <div className="bg-[#ACE03A] md:w-8 md:h-8 w-6 h-6 flex items-center justify-center rounded-full">
//                 <img
//                   src={icon1}
//                   alt="Previous"
//                   onClick={() => handlePrevClick(index)}
//                   className="cursor-pointer"
//                 />
//                 </div>
//                 <div className="bg-[#ACE03A] md:w-8 md:h-8 w-6 h-6 flex items-center justify-center rounded-full">
//                 <img
//                   src={icon2}
//                   alt="Next"
//                   onClick={() => handleNextClick(index)}
//                   className="cursor-pointer "
//                 />
//                 </div>
//               </div>
//             </div>

//             <div className="mt-6 w-full  ">
//               {category.products.length > 0 ? (
//                 visibleCategoryIndex === index ? (
//                   <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-5 px-2 ">
//                     {category.products.map((product) => (
//                       <Link
//                         key={product._id}
//                         to={`/product/${product._id}`}
//                         className="flex flex-col  h-[270px] shadow-md rounded-2xl overflow-hidden "
//                       >
//                         <div className="relative flex items-center justify-center">
//                           <img
//                             src={product.imageUrl}
//                             alt=""
//                             className="bg-cover h-[160px] w-[180px]  rounded-b-xl "
//                           />
//                           <div
//                             className="absolute top-1 right-2 w-[24px] h-[24px] rounded-full flex items-center justify-center cursor-pointer"
//                             onClick={(e) => {
//                               e.preventDefault();
//                               e.stopPropagation();
//                               handleAddToWishlist(e, product);
//                             }}
//                           >
//                             {wishlistItems.some(
//                               (item) => item._id === product._id
//                             ) ? (
//                               <FaHeart className="text-red-500" size={28} />
//                             ) : (
//                               <FaRegHeart className="text-gray-300" size={28} />
//                             )}
//                           </div>
//                         </div>

//                         <div className="flex flex-col gap-2   px-4">
//                           <p className="text-xs  text-[#ADADAD] ">
//                             {category.name}
//                           </p>
//                           <p className="font-semibold text-[16px] text-[#253D4E] ">
//                             {product.name.length > 17
//                               ? product.name.slice(0, 17) + "..."
//                               : product.name}
//                           </p>

//                           <div className="flex justify-between">
//                             <div className="flex gap-2">
//                               {product.retailPrice > 0 && (
//                                 <p className="text-[14px] text-[#ADADAD] line-through RegularFont">
//                                   € {product.retailPrice}
//                                 </p>
//                               )}
//                               <div></div>
//                               <p className="font-semibold text-lg my-0 text-[#ACE03A] ">
//                                 € {product.price}
//                               </p>
//                             </div>

//                             <div>
//                               <div className="flex items-center flex-col  ">
//                                 <button
//                                   onClick={(e) => {
//                                     e.preventDefault();
//                                     e.stopPropagation();
//                                     handleAddToCart(product);
//                                   }}
//                                   disabled={cartItems.some(
//                                     (item) => item._id === product._id
//                                   )}
//                                   className={`flex items-center justify-between py-1 px-1 w-[75px]  ${
//                                     cartItems.some(
//                                       (item) => item._id === product._id
//                                     )
//                                       ? "bg-gray-300 cursor-not-allowed"
//                                       : "bg-[#ACE03A]"
//                                   } rounded-md `}
//                                 >
//                                   <PiShoppingCartLight
//                                     size={18}
//                                     color="#040404"
//                                   />
//                                   <p className="text-[#040404] text-[14px] font-medium">
//                                     Add
//                                   </p>
//                                 </button>
//                               </div>
//                             </div>
//                           </div>
//                         </div>
//                       </Link>
//                     ))}
//                   </div>
//                 ) : (
//                   <Slider
//                     ref={(el) => (sliderRefs.current[index] = el)}
//                     {...settings}
//                     className="gap-[10px]"
//                   >
//                     {category.products.map((product) => (
//                       <Link
//                         key={product._id}
//                         to={`/product/${product._id}`}
//                         className="flex flex-col  h-[270px] shadow-md rounded-2xl overflow-hidden "
//                       >
//                         <div className="relative flex items-center justify-center">
//                           <img
//                             src={product.imageUrl}
//                             alt=""
//                             className="bg-cover h-[160px] w-[180px]  rounded-b-xl "
//                           />
//                           <div
//                             className="absolute top-1 right-2 w-[24px] h-[24px] rounded-full flex items-center justify-center cursor-pointer"
//                             onClick={(e) => {
//                               e.preventDefault();
//                               e.stopPropagation();
//                               handleAddToWishlist(e, product);
//                             }}
//                           >
//                             {wishlistItems.some(
//                               (item) => item._id === product._id
//                             ) ? (
//                               <FaHeart className="text-red-500" size={28} />
//                             ) : (
//                               <FaRegHeart className="text-gray-300" size={28} />
//                             )}
//                           </div>
//                         </div>

//                         <div className="flex flex-col gap-2   px-4">
//                           <p className="text-xs  text-[#ADADAD] ">
//                             {category.name}
//                           </p>
//                           <p className="font-semibold text-[16px] text-[#253D4E] ">
//                             {product.name.length > 17
//                               ? product.name.slice(0, 17) + "..."
//                               : product.name}
//                           </p>

//                           <div className="flex justify-between">
//                             <div className="flex gap-2">
//                               {product.retailPrice > 0 && (
//                                 <p className="text-[14px] text-[#ADADAD] line-through RegularFont">
//                                   € {product.retailPrice}
//                                 </p>
//                               )}
//                               <div></div>
//                               <p className="font-semibold text-lg my-0 text-[#ACE03A] ">
//                                 € {product.price}
//                               </p>
//                             </div>

//                             <div>
//                               <div className="flex items-center flex-col  ">
//                                 <button
//                                   onClick={(e) => {
//                                     e.preventDefault();
//                                     e.stopPropagation();
//                                     handleAddToCart(product);
//                                   }}
//                                   disabled={cartItems.some(
//                                     (item) => item._id === product._id
//                                   )}
//                                   className={`flex items-center justify-between py-1 px-1 w-[75px]  ${
//                                     cartItems.some(
//                                       (item) => item._id === product._id
//                                     )
//                                       ? "bg-gray-300 cursor-not-allowed"
//                                       : "bg-[#ACE03A]"
//                                   } rounded-md `}
//                                 >
//                                   <PiShoppingCartLight
//                                     size={18}
//                                     color="#040404"
//                                   />
//                                   <p className="text-[#040404] text-[14px] font-medium">
//                                     Add
//                                   </p>
//                                 </button>
//                               </div>
//                             </div>
//                           </div>
//                         </div>
//                       </Link>
//                     ))}
//                   </Slider>
//                 )
//               ) : (
//                 <p>No products found in this category.</p>
//               )}
//             </div>
//             {index === 2 && <Landingpage />}
//           </div>
//         ))
//       ) : (
//         <p>No categories found.</p>
//       )}

//       <div className="mt-20">
//         <ReactPaginate
//           previousLabel={"Previous"}
//           nextLabel={"Next"}
//           breakLabel={"..."}
//           pageCount={totalPages}
//           marginPagesDisplayed={2}
//           pageRangeDisplayed={3}
//           onPageChange={handlePageClick}
//           containerClassName={"pagination"}
//           pageClassName={"page-item"}
//           pageLinkClassName={"page-link"}
//           previousClassName={"page-item"}
//           previousLinkClassName={"page-link"}
//           nextClassName={"page-item"}
//           nextLinkClassName={"page-link"}
//           activeClassName={"active"}
//         />
//       </div>
//     </div>
//   );
// }

// export default Snacks;

import ReactPaginate from "react-paginate";
import React, { useRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchProducts } from "../../redux/slices/productSlice";
import {
  addToWishlist,
  removeFromWishlist,
} from "../../redux/slices/wishlistSlice";
import icon1 from "../../assets/images/Chevron left.png";
import icon2 from "../../assets/images/Chevron right.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./snacks.css";
import "../../../src/index.css";
import API from "../../api/apiService";
import { ENDPOINTS } from "../../api/apiRoutes";
import { Link, useNavigate } from "react-router-dom";
import { FaRegHeart, FaHeart } from "react-icons/fa";
import { addToCart, updateQuantity } from "../../redux/slices/cartSlice";
import { toast } from "react-toastify";
import { PiShoppingCartLight } from "react-icons/pi";
import { TailSpin } from "react-loader-spinner";
import Landingpage from "../Bannercomp/Bannercomp";

function Snacks() {
  const { searchQuery } = useSelector((state) => state.product);
  const wishlistItems = useSelector((state) => state.wishlist.items);
  const cartItems = useSelector((state) => state.cart.cartItems);
  const [categories, setCategories] = useState([]);
  const [visibleCategoryIndex, setVisibleCategoryIndex] = useState(null);
  const [count, setCount] = useState(1);
  const sliderRefs = useRef([]);
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const categoriesPerPage = 5;
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(true);
  const [productCounts, setProductCounts] = useState({});
  const [maxReached, setMaxReached] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchProducts());
  }, [dispatch]);

  const handleSlideClick = (param) => {
    navigate(`/orderPlacement?name=${param.name}&id=${param.id}`);
  };

  const fetchCategories = async (page) => {
    setLoading(true);
    try {
      const response = await API.get(
        `${ENDPOINTS.GET_ALLPRODUCTS}?page=${page}&limit=${categoriesPerPage}`
      );

      const uniqueCategoriesMap = new Map();

      response.inventoryItems.forEach((item) => {
        const categoryId = item.category?._id;
        const categoryName = item.category?.name;

        if (categoryId) {
          if (uniqueCategoriesMap.has(categoryId)) {
            uniqueCategoriesMap.get(categoryId).products.push(item);
          } else {
            uniqueCategoriesMap.set(categoryId, {
              _id: categoryId,
              name: categoryName,
              products: [item],
            });
          }
        }
      });

      const uniqueCategories = Array.from(uniqueCategoriesMap.values());
      setCategories(uniqueCategories);
      setTotalPages(response.totalPages);
      setLoading(false);
    } catch (err) {
      console.error("Error fetching categories:", err);
    }
  };

  useEffect(() => {
    fetchCategories(currentPage);
  }, [currentPage]);

  const filteredCategories = categories
    .map((category) => ({
      ...category,
      products:
        category.products?.filter((product) =>
          product.name.toLowerCase().includes(searchQuery.toLowerCase())
        ) || [],
    }))
    .filter((category) => category.products.length > 0);

  // const handleIncrement = (productId) => {
  //   const updatedCount = (productCounts[productId] || 1) + 1;
  //   setProductCounts((prevCounts) => ({
  //     ...prevCounts,
  //     [productId]: updatedCount,
  //   }));
  //   dispatch(updateQuantity({ productId, quantity: updatedCount }));
  // };

  const handleDecrement = (productId) => {
    const updatedCount = (productCounts[productId] || 1) - 1;
    if (updatedCount < 1) return;
    setProductCounts((prevCounts) => ({
      ...prevCounts,
      [productId]: updatedCount,
    }));
    dispatch(updateQuantity({ productId, quantity: updatedCount }));
  };

  // const handleAddToCart = (product) => {
  //   const count = productCounts[product._id] || 1;
  //   dispatch(
  //     addToCart({
  //       ...product,
  //       quantity: count,
  //       stock: product.quantity,
  //     })
  //   );
  //   toast.success("Item added to cart successfully!");
  // };
  const handleIncrement = (productId) => {
    const updatedCount = (productCounts[productId] || 1) + 1;
    if (updatedCount > 100) {
      toast.error("You can only add up to 100 items.");
      return;
    }
    setProductCounts((prevCounts) => ({
      ...prevCounts,
      [productId]: updatedCount,
    }));
    dispatch(updateQuantity({ productId, quantity: updatedCount }));
  };
  
  const handleAddToCart = (product) => {
    const count = productCounts[product._id] || 1;
    if (count > 100) {
      toast.error("You can only add up to 100 items.");
      return;
    }
    dispatch(
      addToCart({
        ...product,
        quantity: count,
        stock: product.quantity,
      })
    );
    toast.success("Item added to cart successfully!");
  };

  const offset = (currentPage - 1) * categoriesPerPage;
  const displayedCategories = filteredCategories.slice(
    offset,
    offset + categoriesPerPage
  );

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected + 1);
    window.scrollTo(0, 0);
  };

  const settings = {
    infinite: false,
    speed: 1000,
    slidesToShow: 6,
    slidesToScroll: 3,
    initialSlide: 0,
    arrows: false,
    centerMode: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 2,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
    ],
  };

  const handlePrevClick = (index) => {
    if (sliderRefs.current[index]) {
      sliderRefs.current[index].slickPrev();
    }
  };

  const handleNextClick = (index) => {
    if (sliderRefs.current[index]) {
      sliderRefs.current[index].slickNext();
    }
  };

  const handleSeeMoreClick = (index) => {
    setVisibleCategoryIndex(visibleCategoryIndex === index ? null : index);
  };

  const handleAddToWishlist = (e, product) => {
    e.stopPropagation();
    const isInWishlist = wishlistItems.some((item) => item._id === product._id);
    if (isInWishlist) {
      dispatch(removeFromWishlist(product._id));
      toast.error("Item Removed from Wishlist");
    } else {
      dispatch(addToWishlist(product));
      toast.success("Item Added to Wishlist");
    }
  };

  return (
    <div className="md:py-[24px] py-[12px] md:px-[32px] px-[16px] ">
      {loading ? (
        <div className="flex justify-center items-center h-[400px]">
          <TailSpin
            height="80"
            width="80"
            color="#ACE03A"
            ariaLabel="tail-spin-loading"
            radius="1"
            visible={true}
          />
        </div>
      ) : filteredCategories.length > 0 ? (
        filteredCategories.map((category, index) => (
          <div key={category._id} className="mb-8">
            <div className="flex justify-between w-full items-center ">
              <div className=" ">
                <p className="font-medium md:text-2xl text-base text-[#344054] ">
                  {category.name}
                </p>
              </div>
              <div className="flex md:gap-[16px] gap-[8px]  ">
                {/* <button
                  className="md:text-base text-sm text-[#000] font-medium underline"
                  onClick={() => handleSeeMoreClick(index)}
                >
                  {visibleCategoryIndex === index ? "See Less" : "See All"}
                </button> */}
                {/* <button
                  className="md:text-lg text-sm text-[#253D4E] font-bold underline"
                  onClick={() =>
                    handleSlideClick({ name: category.name, id: category._id })
                  }
                >
                  See All
                </button> */}
                <div className="bg-[#ACE03A] md:w-8 md:h-8 w-6 h-6 flex items-center justify-center rounded-full">
                  <img
                    src={icon1}
                    alt="Previous"
                    onClick={() => handlePrevClick(index)}
                    className="cursor-pointer"
                  />
                </div>
                <div className="bg-[#ACE03A] md:w-8 md:h-8 w-6 h-6 flex items-center justify-center rounded-full">
                  <img
                    src={icon2}
                    alt="Next"
                    onClick={() => handleNextClick(index)}
                    className="cursor-pointer "
                  />
                </div>
              </div>
            </div>

            <div className="mt-6 w-full  ">
              {category.products.length > 0 ? (
                visibleCategoryIndex === index ? (
                  <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-5 px-2 ">
                    {category.products.map((product) => (
                      <Link
                        key={product._id}
                        to={`/product/${product._id}`}
                        className="flex flex-col  h-[230px] shadow-md rounded-2xl overflow-hidden "
                      >
                        <div className="relative flex items-center justify-center">
                          <img
                            src={product.imageUrl}
                            alt=""
                            className="bg-cover h-[120px] w-[140px]  rounded-b-xl "
                          />
                          <div
                            className="absolute top-1 right-2 w-[24px] h-[24px] rounded-full flex items-center justify-center cursor-pointer"
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              handleAddToWishlist(e, product);
                            }}
                          >
                            {wishlistItems.some(
                              (item) => item._id === product._id
                            ) ? (
                              <FaHeart className="text-red-500" size={28} />
                            ) : (
                              <FaRegHeart className="text-gray-300" size={28} />
                            )}
                          </div>
                        </div>

                        <div className="flex flex-col gap-2   px-4">
                          <p className="text-xs  text-[#ADADAD] ">
                            {category.name}
                          </p>
                          <p className="font-semibold text-[16px] text-[#253D4E] ">
                            {product.name.length > 17
                              ? product.name.slice(0, 17) + "..."
                              : product.name}
                          </p>

                          <div className="flex justify-between">
                            <div className="flex gap-2">
                              {product.retailPrice > 0 && (
                                <p className="text-[14px] text-[#ADADAD] line-through RegularFont">
                                  € {product.retailPrice}
                                </p>
                              )}
                              <div></div>
                              <p className="font-semibold text-lg my-0 text-[#ACE03A] ">
                                € {product.price}
                              </p>
                            </div>

                            <div>
                              <div className="flex items-center flex-col  ">
                                <button
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    handleAddToCart(product);
                                  }}
                                  disabled={cartItems.some(
                                    (item) => item._id === product._id
                                  )}
                                  className={`flex items-center justify-between py-1 px-1 w-[75px]  ${
                                    cartItems.some(
                                      (item) => item._id === product._id
                                    )
                                      ? "bg-gray-300 cursor-not-allowed"
                                      : "bg-[#ACE03A]"
                                  } rounded-md `}
                                >
                                  <PiShoppingCartLight
                                    size={18}
                                    color="#040404"
                                  />
                                  <p className="text-[#040404] text-[14px] font-medium">
                                    Add
                                  </p>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    ))}
                  </div>
                ) : (
                  <Slider
                    ref={(el) => (sliderRefs.current[index] = el)}
                    {...settings}
                    className="gap-[10px]"
                  >
                    {category.products.map((product,productIndex) => (
                      <Link
                        key={product._id}
                        to={`/product/${product._id}`}
                        className="flex flex-col h-[210px]  md:h-[230px] lg:h-[240px] xl:h-[230px] shadow-md rounded-2xl overflow-hidden "
                      >
                        <div className="relative flex items-center justify-center">
                          <img
                            src={product.imageUrl}
                            alt=""
                            className="bg-cover h-[120px] w-[140px]  rounded-b-xl "
                          />
                          <div
                            className="absolute top-1 right-2 w-[24px] h-[24px] rounded-full flex items-center justify-center cursor-pointer"
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              handleAddToWishlist(e, product);
                            }}
                          >
                            {wishlistItems.some(
                              (item) => item._id === product._id
                            ) ? (
                              <FaHeart className="text-red-500" size={28} />
                            ) : (
                              <FaRegHeart className="text-gray-300" size={28} />
                            )}
                          </div>
                        </div>

                        <div className="flex flex-col gap-2   px-4">
                          <p className="text-xs  text-[#ADADAD] ">
                            {category.name}
                          </p>
                          <p className="font-semibold text-[16px] text-[#253D4E] ">
                            {product.name.length > 17
                              ? product.name.slice(0, 17) + "..."
                              : product.name}
                          </p>

                          <div className="flex justify-between">
                            <div className="flex gap-2">
                              {product.retailPrice > 0 && (
                                <p className="text-[14px] text-[#ADADAD] line-through RegularFont">
                                  € {product.retailPrice}
                                </p>
                              )}
                              <div></div>
                              <p className="font-semibold text-lg my-0 text-[#ACE03A] ">
                                € {product.price}
                              </p>
                            </div>

                            <div>
                              <div className="flex items-center flex-col">
                                {cartItems.some(
                                  (item) => item._id === product._id
                                ) ? (
                                  <div className="flex items-center gap-2">
                                    <button
                                      onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        handleDecrement(product._id);
                                      }}
                                      className="bg-[#ACE03A] text-[#040404] w-6 h-6 rounded-full items-center justify-center"
                                    >
                                      -
                                    </button>
                                    <span className="text-[#253D4E] bg-[#F3F3F3] w-[26px] h-[22px] items-center flex justify-center">
                                      {productCounts[product._id] || 1}
                                    </span>
                                    <button
                                      onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        handleIncrement(product._id);
                                      }}
                                      className="bg-[#ACE03A] text-[#040404] w-6 h-6 rounded-full items-center justify-center"
                                    >
                                      +
                                    </button>
                                  </div>
                                ) : (
                                  // Render "Add" button
                                  <button
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      handleAddToCart(product);
                                    }}
                                    className="flex items-center justify-between py-1 px-1 w-[75px] bg-[#ACE03A] rounded-md"
                                  >
                                    <PiShoppingCartLight
                                      size={18}
                                      color="#040404"
                                    />
                                    <p className="text-[#040404] text-[14px] font-medium">
                                      Add
                                    </p>
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    ))}
                    {category.products.length > 0 && (
                      <div
                        key={`see-all-${category._id}`}
                        className="flex flex-col items-center justify-center cursor-pointer mt-20 ml-10"
                        onClick={() =>
                          handleSlideClick({
                            name: category.name,
                            id: category._id,
                          })
                        }
                      >
                        {/* <button className="text-2xl font-semibold text-[#ACE03A]">
        See All
        click to explore
      </button> */}
                        <p className="text-2xl font-semibold text-[#ACE03A]">
                          See All
                        </p>
                        <p className="text-sm  text-[#ADADAD] ">
                          Click to explore
                        </p>
                      </div>
                    )}
                  </Slider>
                )
              ) : (
                <p>No products found in this category.</p>
              )}
            </div>
            {index === 2 && <Landingpage />}
          </div>
        ))
      ) : (
        <p>No categories found.</p>
      )}

      <div className="mt-20">
        <ReactPaginate
          previousLabel={"Previous"}
          nextLabel={"Next"}
          breakLabel={"..."}
          pageCount={totalPages}
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          pageClassName={"page-item"}
          pageLinkClassName={"page-link"}
          previousClassName={"page-item"}
          previousLinkClassName={"page-link"}
          nextClassName={"page-item"}
          nextLinkClassName={"page-link"}
          activeClassName={"active"}
        />
      </div>
    </div>
  );
}

export default Snacks;
