import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoggedIn: false,
  Admin: false,
  user: null,
  verificationPending: false,
};

const loadStateFromLocalStorage = () => {
  try {
    const serializedState = localStorage.getItem("loginState");
    if (serializedState === null) {
      return initialState;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return initialState;
  }
};

const initialStateFromLocalStorage = loadStateFromLocalStorage();

const loginSlice = createSlice({
  name: "login",
  initialState: initialStateFromLocalStorage,
  reducers: {
    login(state, action) {
      state.user = action.payload.user;
      state.Admin = action.payload.isAdmin;
      state.isLoggedIn = action.payload.isAdmin ? false : true;
      state.verificationPending = action.payload.isAdmin;

      localStorage.setItem("loginState", JSON.stringify(state));
    },
    logout(state) {
      state.isLoggedIn = false;
      state.user = null;
      state.Admin = false;
      state.verificationPending = false;

      localStorage.removeItem("loginState");
    },
    verifyAdmin(state, action) {
      state.isLoggedIn = true;
      state.verificationPending = false;

      localStorage.setItem("loginState", JSON.stringify(state));
    },
    verifyFailure(state) {
      state.isLoggedIn = false;
      state.verificationPending = false;

      localStorage.setItem("loginState", JSON.stringify(state));
    },
  },
});

export const { login, logout, verifyAdmin, verifyFailure } = loginSlice.actions;

export default loginSlice.reducer;
