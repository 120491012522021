import React from "react";
import "../../index.css";
import { useSelector } from "react-redux";
import * as XLSX from "xlsx";

function TotalOrders() {
  const orders = useSelector((state) => state.orders.orders);

  const handleExportToExcel = () => {
    if (orders && orders.length > 0) {
      const worksheet = XLSX.utils.json_to_sheet(
        orders.map((order) => ({
          "Order No": order._id,
          "Customer Name": order.shippingAddress.Address.fullName,
          "Order Total": order.cart.length,
          Status: order.status,
        }))
      );

      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Orders");

      XLSX.writeFile(workbook, "orders.xlsx");
    } else {
      alert("No orders to export");
    }
  };

  return (
    <>
      <div className="md:px-10 px-8 md:py-10 py-5">
        <div className="flex md:gap-10 gap-4 flex-col">
          <div className="flex flex-wrap justify-between items-center gap-4 ">
            <div className="flex flex-col gap-2">
              <p className="text-[#020617] text-4xl BBoldFont ">Orders</p>
              <p className="text-[#696969] text-base NormalFont">
                Here is a quick overview about your Orders
              </p>
            </div>
            <div className="flex gap-3">
              <div>
                <button
                  onClick={handleExportToExcel}
                  className="bg-[#003E00] text-sm inter-Semi text-white p-[10px] rounded-md"
                >
                  Print Orders
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TotalOrders;
