import React, { useState } from "react";
import { IoMdClose } from "react-icons/io";
import { FaTrash } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import { addToCart } from "../../redux/slices/cartSlice";
import { removeFromWishlist } from "../../redux/slices/wishlistSlice";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

function WishlistSlider({ isOpen, onClose }) {
  const wishlistItems = useSelector((state) => state.wishlist.items);
  const [count, setCount] = useState(1);
  const dispatch = useDispatch();

  if (!isOpen) return null;

  const handleAddToCart = (product) => {
    dispatch(
      addToCart({ ...product, quantity: count, stock: product.quantity })
    );
    toast.success("Item added to cart successfully!");
  };

  const handleRemoveFromWishlist = (_id) => {
    dispatch(removeFromWishlist(_id));
    toast.error("Item removed from wishlist");
  };

  return (
    <div className="fixed top-0 right-0 w-full sm:w-[400px] bg-white h-full shadow-lg z-50 overflow-y-scroll">
      <div className="flex justify-between items-center p-4 border-b">
        <h2 className="text-xl font-semibold">Wishlist</h2>
        <button onClick={onClose} className="text-xl">
          <IoMdClose />
        </button>
      </div>
      <div className="p-4 overflow-y-auto">
        {wishlistItems.length > 0 ? (
          wishlistItems.map((item) => (
            <div key={item._id} className="flex items-center border-b py-4">
            <Link to={`/product/${item._id}`} >
              <img
                src={item.imageUrl}
                alt={item.name}
                className="w-28 h-28 object-cover mr-6 rounded-md"
              />
              </Link>
              <div className="flex-1">
                <h3 className="text-lg font-semibold">{item.name}</h3>
                <p className="text-sm text-gray-600 mt-1">
                  Price: €{item.price.toFixed(2)}
                </p>
                <button
                  onClick={() => handleAddToCart(item)}
                  className="bg-[#ACE03A] text-white py-2 px-2 rounded md:w-28 mt-2"
                >
                  Add to Cart
                </button>
              </div>
              <button
                onClick={() => handleRemoveFromWishlist(item._id)}
                className="text-gray-600 hover:text-red-600 p-2 ml-4"
              >
                <FaTrash className="w-5 h-5" />
              </button>
            </div>
          ))
        ) : (
          <p className="text-center text-gray-600">Your wishlist is empty.</p>
        )}
      </div>
    </div>
  );
}

export default WishlistSlider;
