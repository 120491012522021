import { React, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import loginImage from "../../../../assets/images/login.png";
import "../../../../index.css";
import { useDispatch } from "react-redux";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import { IoEyeOffOutline, IoEyeOutline } from "react-icons/io5";
import API, { ENDPOINTS } from "../../../../api/apiService";

function Forgotpass() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
  });

  
  const handleSubmit = async (values, { setSubmitting }) => {
    setIsSubmitting(true);
    try {
      localStorage.setItem("email", values.email);

      const response = await API.post(ENDPOINTS.FORGOT_PASSWORD, {
        email: values.email,
      });
    

      if (response.message) {
        toast.success(response.message || "OTP sent successfully!");
        navigate("/forgotpassverify"); 
      } else {
        toast.error(response.message || "Failed to send OTP.");
      }
    } catch (error) {
      toast.error(error.response.message || "An error occurred.");
    } finally {
      setSubmitting(false);
      setIsSubmitting(false);
    }
  };

  return (
    <div className="w-[100%]">
      <div className="md:flex justify-center">
        <div className="md:w-[50%] md:h-[100vh] bg-[#004D00]  flex justify-between flex-col ">
          <div className="p-4">
            <IoArrowBackCircleOutline
              size={60}
              color="white"
              onClick={() => navigate("/login")}
              className="cursor-pointer"
            />
          </div>

          <div>
            <img src={loginImage} alt="product" className="" />
          </div>
        </div>
        <div className="md:w-[50%] rounded-2xl flex flex-col justify-center bg-white items-center md:mt-0 mt-5">
          <div>
            <h1 className="md:text-4xl text-3xl font-bold">
              Forgot Your Password!
            </h1>
            <p className="text-xl font-semibold mt-4 mb-6">
              Enter your Email Address
            </p>

            <Formik
              initialValues={{ email: "" }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting }) => (
                <Form className="flex flex-col gap-2 mt-10 md:w-[450px]">
                  <div className="flex flex-col">
                    <label className="font-semibold">Email</label>
                    <Field
                      type="email"
                      name="email"
                      placeholder="Enter your Email"
                      className="border-2 border-gray-400 rounded-md w-full p-2 outline-none"
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="text-red-500"
                    />
                  </div>

                  <button
                    type="submit"
                    className="w-full py-[10px] px-3 bg-[#004D00] text-white rounded-xl text-[16px] mt-10 inter-Semi"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? "Confirming..." : "Confirm"}
                  </button>
                </Form>
              )}
            </Formik>
          </div>
          <p className="text-gray-400 relative top-8 font-light">
            © 2024 Designed by Oliver | All rights reserved
          </p>
        </div>
      </div>
    </div>
  );
}

export default Forgotpass;
