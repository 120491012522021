import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "../../../src/index.css";
import img1 from "../../assets/images/Chevron left.png";
import locationIcon from "../../assets/images/location_on.png";
import "./OrderDelivery.css";
import AddressModal from "../../components/AddressModal/AddressModal";
import API from "../../api/apiService";
import { ENDPOINTS } from "../../api/apiRoutes";
import axios from "axios";
import { clearCart } from "../../redux/slices/cartSlice";
import {
  useElements,
  useStripe,
  CardNumberElement,
} from "@stripe/react-stripe-js";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { StretchVertical } from "lucide-react";
import "react-toastify/dist/ReactToastify.css";

function OrderDelivery() {
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();
  const [selectedPayment, setSelectedPayment] = useState("cashOnDelivery");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [address, setAddress] = useState({
    fullName: "",
    phoneNumber: "",
    street: "",
    state: "",
    city: "",
    postalCode: "",
  });
  const [dropOffInstructions, setDropOffInstructions] = useState("");
  const [orderData, setOrderData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [nameOnCard, setNameOnCard] = useState("");
  const [couponCode, setCouponCode] = useState(null);
  const [discountPrice, setDiscountPrice] = useState(null);
  const [couponCodeData, setCouponCodeData] = useState(null);

  const dispatch = useDispatch();

  const [errors, setErrors] = useState({
    fullName: "",
    email: "",
    phoneNumber: "",
    dropOffInstructions: "",
    streetAddress: "",
    city: "",
    state: "",
    postalCode: "",
  });

  const handleInputChange = (event) => {
    setNameOnCard(event.target.value);
  };
  const location = useLocation();
  const { cartItems } = location.state || { cartItems: [] };

  useEffect(() => {
    const orderData = JSON.parse(localStorage.getItem("cart"));
    setOrderData(orderData || []);
  }, []);

  useEffect(() => {
    const storedUserName = JSON.parse(localStorage.getItem("userName"));
    const storedEmail = localStorage.getItem("email");
    const storedStreet = localStorage.getItem("address");

    setAddress((prevAddress) => ({
      ...prevAddress,
      fullName: storedUserName || "",
      email: storedEmail || "",
      street: storedStreet || "",
    }));
  }, []);

  const subTotal = (cartItems || []).reduce((acc, item) => {
    const price = parseFloat(item.price) || 0;
    const quantity = parseInt(item.quantity, 10) || 0;
    return acc + price * quantity;
  }, 0);

  const deliveryCharges = subTotal * 0;
  const otherCharges = 0;

  const handleRadioChange = (event) => {
    setSelectedPayment(event.target.value);
  };

  const handleAddressSave = (newAddress) => {
    setAddress(newAddress);
  };

  const coupenCodeDiscount = async (e) => {
    e.preventDefault();
    const name = couponCode;

    //   const res = await API.post(
    //     `${ENDPOINTS.APPLYCOUPON_CODE}/${name}`
    //     // `http://localhost:5000/api/applyCouponCode/${name}`
    //   );
    //   const couponCodeValue = res.couponCode?.value;
    //   if (res.success) {
    //     const totalDiscountprice = (subTotal * couponCodeValue) / 100;
    //     setDiscountPrice(totalDiscountprice);
    //     setCouponCodeData(res.couponCode);
    //   } else if(!res.success) {
    //     toast.error("Invalid or inactive coupon code");
    //     setDiscountPrice(null);
    //   }
    // };
    try {
      const res = await API.post(`${ENDPOINTS.APPLYCOUPON_CODE}/${name}`);
      if (res.success && res.couponCode) {
        const couponCodeValue = res.couponCode.value;
        const totalDiscountprice = (subTotal * couponCodeValue) / 100;

        setDiscountPrice(totalDiscountprice);
        setCouponCodeData(res.couponCode);
      } else {
        toast.error("Invalid or inactive coupon code");
        setDiscountPrice(null);
      }
    } catch (error) {
      console.error("Error applying coupon:", error);
      toast.error("Invalid or Inactive coupon code");
      setDiscountPrice(null);
    }
  };

  const discountPercentenge = couponCodeData ? discountPrice : "";
  // console.log(discountPercentenge, discountPrice, couponCodeData);

  const total = couponCodeData
    ? (subTotal + deliveryCharges - discountPercentenge).toFixed(2)
    : (subTotal + deliveryCharges).toFixed(2);

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    setErrors({
      fullName: "",
      email: "",
      phoneNumber: "",
      dropOffInstructions: "",
      streetAddress: "",
      city: "",
      state: "",
      postalCode: "",
    });

    let hasError = false;
    if (!address.fullName.trim()) {
      setErrors((prevState) => ({
        ...prevState,
        fullName: "Full name is required.",
      }));
      hasError = true;
    }

    if (!validateEmail(address.email)) {
      setErrors((prevState) => ({
        ...prevState,
        email: "Please enter a valid email address.",
      }));
      hasError = true;
    }

    // if (!address.phoneNumber.trim() || !/^\d{13}$/.test(address.phoneNumber)) {
    //   setErrors((prevState) => ({
    //     ...prevState,
    //     phoneNumber: "Phone number must be a 13-digit number.",
    //   }));
    //   hasError = true;
    // }
    if (!dropOffInstructions) {
      setErrors((prevState) => ({
        ...prevState,
        dropOffInstructions: "Drop off instructions are required.",
      }));
      hasError = true;
    }

    if (!address.street.trim()) {
      setErrors((prevState) => ({
        ...prevState,
        streetAddress: "Street address is required.",
      }));
      hasError = true;
    }

    // City validation
    if (!address.city.trim()) {
      setErrors((prevState) => ({
        ...prevState,
        city: "City is required.",
      }));
      hasError = true;
    }

    // State validation
    if (!address.state.trim()) {
      setErrors((prevState) => ({
        ...prevState,
        state: "State is required.",
      }));
      hasError = true;
    }

    // Postal Code validation
    if (!address.postalCode.trim() || !/^\d{5}$/.test(address.postalCode)) {
      setErrors((prevState) => ({
        ...prevState,
        postalCode: "Postal code must be a 5-digit number.",
      }));
      hasError = true;
    }

    if (hasError) {
      setLoading(false);
      return;
    }

    const userId = localStorage.getItem("_id");

    const order = {
      cart: orderData,
      shippingAddress: {
        Address: address,
        DropOffInstructions: dropOffInstructions,
      },
      totalPrice: total,
      otherPrice: {
        deliveryCharges: deliveryCharges,
        otherCharges: otherCharges,
      },
      userId: userId,
    };

    try {
      if (selectedPayment === "cashOnDelivery") {
        console.log("COD", order);
        order.paymentInfo = {
          type: "cash on delivery",
        };

        const res = await API.post(ENDPOINTS.CREATE_ORDER, order);
        console.log("apiresponse", res);

        if (res.success) {
          dispatch(clearCart());
          navigate("/payment-success");
          toast.success("order Create successfull");
        }
      } else if (selectedPayment === "creditCard") {
        const { data } = await axios.post(
          "https://eurofreshmart-backend-xho8.onrender.com/api/process",
          { amount: Math.round(total) },
          { headers: { "Content-Type": "application/json" } }
        );

        const client_secret = data.client_secret;
        console.log("credit card data", data);
        if (!stripe || !elements) return;
        console.log("error stripe");
        const { error, paymentIntent } = await stripe.confirmCardPayment(
          client_secret,
          {
            payment_method: {
              card: elements.getElement(CardNumberElement),
            },
          }
        );

        if (error) {
          toast.error("Payment failed! Please try again.");
          console.error("Payment error:", error);
        } else if (paymentIntent.status === "succeeded") {
          order.paymentInfo = {
            id: paymentIntent.id,
            status: paymentIntent.status,
            type: "Credit Card",
          };
          const res = await API.post(ENDPOINTS.CREATE_ORDER, order);
          if (res.success) {
            dispatch(clearCart());
            toast.success("Payment successful!");
            navigate("/payment-success");
          }
        }
      }
    } catch (error) {
      toast.error("Order submission failed. Please try again.");
      console.error("Order submission error:", error);
    } finally {
      setLoading(false);
    }
  };

  const totalItems = cartItems.reduce(
    (total, item) => total + item.quantity,
    0
  );

  return (
    <>
      <div className="md:py-[10px] py-[10px] md:px-[32px] px-[16px] mt-10">
        <button
          className="flex gap-2 items-center"
          onClick={() => navigate(-1)}
        >
          <img src={img1} alt="" />
          <p className="text-xl MediumFont">Back to store</p>
        </button>
        <div className="mt-10 flex md:flex-row flex-col gap-10">
          <div className="md:w-[60%] w-full">
            <p className="text-[32px] MediumFont">Checkout</p>

            <div className="text-xl MediumFont mt-10">Personal Details</div>
            <div className="flex gap-5 mt-4 md:flex-row flex-col">
              <div className="flex flex-col gap-1 w-full">
                <label htmlFor="">Full Name</label>
                <input
                  type="text"
                  placeholder="Full Name"
                  className={`outline-none border p-2 rounded-md ${
                    errors.fullName ? "border-red-500" : ""
                  }`}
                  value={address.fullName}
                  onChange={(e) =>
                    setAddress({ ...address, fullName: e.target.value })
                  }
                />
                {errors.fullName && (
                  <p className="text-red-500">{errors.fullName}</p>
                )}
              </div>
              <div className="flex flex-col gap-1 w-full">
                <label htmlFor="">Email Address</label>
                <input
                  type="text"
                  placeholder="Email Address"
                  className={`outline-none border p-2 rounded-md ${
                    errors.email ? "border-red-500" : ""
                  }`}
                  value={address.email}
                  onChange={(e) =>
                    setAddress({ ...address, email: e.target.value })
                  }
                />
                {errors.email && <p className="text-red-500">{errors.email}</p>}
              </div>
            </div>

            {/* <div className="flex flex-col gap-1 mt-4 md:w-[49%] w-full">
            <label htmlFor="">Phone Number</label>
            <input
              type="text"
              placeholder="Phone Number"
              className={`outline-none border p-2 rounded-md ${errors.phoneNumber ? "border-red-500" : ""}`}
              value={address.phoneNumber}
              onChange={(e) => setAddress({ ...address, phoneNumber: e.target.value })}
            />
            {errors.phoneNumber && <p className="text-red-500">{errors.phoneNumber}</p>}
          </div> */}
            <div className="flex flex-col gap-1 mt-4 md:w-[49%] w-full">
              <label htmlFor="phone-number">Phone Number</label>
              <input
                id="phone-number"
                type="text"
                placeholder="+358"
                className={`outline-none border p-2 rounded-md ${
                  errors.phoneNumber ? "border-red-500" : ""
                }`}
                value={address.phoneNumber}
                onChange={(e) => {
                  const input = e.target.value;
                  // Ensure the input starts with "+358"
                  if (!input.startsWith("+358")) {
                    setAddress({ ...address, phoneNumber: "+358" });
                    return;
                  }
                  // Limit to 9 digits after "+358"
                  const digits = input.slice(4).replace(/\D/g, "");
                  if (digits.length <= 9) {
                    setAddress({ ...address, phoneNumber: `+358${digits}` });
                  }
                }}
                onBlur={() => {
                  // Show error if the total length is not correct
                  if (address.phoneNumber.length !== 13) {
                    setErrors((prevState) => ({
                      ...prevState,
                      phoneNumber:
                        "Phone number must have 9 digits after +358.",
                    }));
                  } else {
                    setErrors((prevState) => ({
                      ...prevState,
                      phoneNumber: "",
                    }));
                  }
                }}
              />
              {errors.phoneNumber && (
                <p className="text-red-500">{errors.phoneNumber}</p>
              )}
            </div>

            <div className="flex gap-4 my-6 flex-col">
              <p className="text-xl MediumFont">Drop Off Address</p>
              <div className="flex gap-2 items-center">
                <img src={locationIcon} alt="Location" className="w-6 h-6" />
                <p className="text-lg NormalFont">
                  {`${address.street} ${address.city} ${address.state} ${address.postalCode}`}
                </p>
                <button
                  onClick={() => setIsModalOpen(true)}
                  className="ml-4 text-xl font-semibold underline"
                >
                  Edit
                </button>
              </div>
            </div>

            <div className="flex gap-5 w-full items-center md:flex-row flex-col">
              <div className="flex flex-col w-full">
                <label htmlFor="">Street Address</label>
                <input
                  type="text"
                  placeholder="Street Address"
                  className={`outline-none border p-2 rounded-md ${
                    errors.streetAddress ? "border-red-500" : ""
                  }`}
                  value={address.street}
                  onChange={(e) =>
                    setAddress({ ...address, street: e.target.value })
                  }
                />
                {errors.streetAddress && (
                  <p className="text-red-500">{errors.streetAddress}</p>
                )}
              </div>

              <div className="flex flex-col  w-full">
                <label htmlFor="">City</label>
                <input
                  type="text"
                  placeholder="City"
                  className={`outline-none border p-2 rounded-md ${
                    errors.city ? "border-red-500" : ""
                  }`}
                  value={address.city}
                  onChange={(e) =>
                    setAddress({ ...address, city: e.target.value })
                  }
                />
                {errors.city && <p className="text-red-500">{errors.city}</p>}
              </div>
            </div>

            <div className="flex gap-5 w-full items-center mt-4 md:flex-row flex-col">
              <div className="flex flex-col gap-1 w-full">
                <label htmlFor="">State</label>
                <input
                  type="text"
                  placeholder="State"
                  className={`outline-none border p-2 rounded-md ${
                    errors.state ? "border-red-500" : ""
                  }`}
                  value={address.state}
                  onChange={(e) =>
                    setAddress({ ...address, state: e.target.value })
                  }
                />
                {errors.state && <p className="text-red-500">{errors.state}</p>}
              </div>

              <div className="flex flex-col gap-1 w-full">
                <label htmlFor="">Postal Code</label>
                <input
                  type="text"
                  placeholder="Postal Code"
                  className={`outline-none border p-2 rounded-md ${
                    errors.postalCode ? "border-red-500" : ""
                  }`}
                  value={address.postalCode}
                  onChange={(e) =>
                    setAddress({ ...address, postalCode: e.target.value })
                  }
                />
                {errors.postalCode && (
                  <p className="text-red-500">{errors.postalCode}</p>
                )}
              </div>
            </div>

            <div className="flex flex-col gap-4 mt-10">
              <p className="text-xl MediumFont">Drop Off Instruction</p>
              <textarea
                className={`border-2 focus:outline-none h-[156px] rounded-2xl p-2 ${
                  errors.dropOffInstructions ? "border-red-500" : ""
                }`}
                placeholder="Type here"
                value={dropOffInstructions}
                onChange={(e) => setDropOffInstructions(e.target.value)}
              ></textarea>
              {errors.dropOffInstructions && (
                <p className="text-red-500">{errors.dropOffInstructions}</p>
              )}
            </div>

            <div className="mt-20 flex flex-col gap-6">
              <h1 className="text-[32px] MediumFont">Payment Option</h1>

              <div className="flex justify-between py-2 border-b border-b-gray-300 items-center">
                <h1 className="text-xl MediumFont">Credit Card</h1>
                <label className="cursor-pointer">
                  <input
                    type="radio"
                    value="creditCard"
                    checked={selectedPayment === "creditCard"}
                    onChange={handleRadioChange}
                    className="hidden"
                  />
                  <div className="w-[22px] h-[22px] rounded-full border-2 bg-slate-100 border-[#475467] items-center flex justify-center cursor-pointer">
                    <div
                      className={`w-[18px] h-[18px] rounded-full ${
                        selectedPayment === "creditCard"
                          ? "bg-[#ACE03A]"
                          : "bg-transparent"
                      }`}
                    />
                  </div>
                </label>
              </div>

              {selectedPayment === "creditCard" && (
                <div className="w-full flex border-b">
                  <form className="w-full" onSubmit={handleSubmit}>
                    <div className="w-full flex gap-3 pb-3">
                      <div className="w-[50%]">
                        <label className="block pb-2">Name On Card</label>
                        <input
                          required
                          placeholder="Name on Card"
                          className={`w-full border p-2 rounded-[5px outline-none`}
                          value={nameOnCard}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="w-[50%]">
                        <label className="block pb-2">Exp Date</label>
                        <input
                          required
                          placeholder="Exp Date"
                          className={`w-full border p-2 rounded-[5px] outline-none`}
                        />
                      </div>
                    </div>

                    <div className="w-full flex gap-3 pb-3">
                      <div className="w-[50%] ">
                        <label className="block pb-2">Card Number</label>
                        <input
                          required
                          placeholder="3711 1111 1111 114"
                          className={`w-full border p-2 rounded-[5px]  outline-none `}
                        />
                      </div>
                      <div className="w-[50%]">
                        <label className="block pb-2">CVV</label>
                        <input
                          placeholder="1234"
                          className={`w-full border p-2 rounded-[5px]  outline-none `}
                        />
                      </div>
                    </div>
                    <input
                      type="submit"
                      value="Submit"
                      className={`w-[150px]  h-[50px] my-3 flex items-center justify-center rounded-xl cursor-pointer !bg-[#003E00] text-[#fff]  text-[18px] font-[600]`}
                      disabled={loading}
                    />
                  </form>
                </div>
              )}
            </div>
            <div className="flex justify-between py-2 border-b border-b-gray-300 items-center mb-10">
              <h1 className="text-xl MediumFont">Cash on Delivery</h1>
              <label className="cursor-pointer">
                <input
                  type="radio"
                  value="cashOnDelivery"
                  checked={selectedPayment === "cashOnDelivery"}
                  onChange={handleRadioChange}
                  className="hidden"
                />
                <div className="w-[22px] h-[22px] rounded-full border-2 bg-slate-100 border-[#475467] items-center flex justify-center cursor-pointer">
                  <div
                    className={`w-[18px] h-[18px] rounded-full ${
                      selectedPayment === "cashOnDelivery"
                        ? "bg-[#ACE03A]"
                        : "bg-transparent"
                    }`}
                  />
                </div>
              </label>
            </div>
          </div>

          <div className="md:w-[40%] md:h-[100%] border border-gray-300 rounded-3xl p-4 flex flex-col gap-8">
          <div className="flex flex-col gap-1">
            <h1 className="text-[32px] MediumFont">Order Summary</h1>
            <p className="text-lg font-semibold">
              Total Items in Cart: {totalItems}
            </p>
            </div>
            <div className="flex flex-col gap-4 md:h-[300px] overflow-y-scroll">
              {cartItems.map((item, index) => (
                <div key={index} className="flex gap-4 mb-4">
                  <img
                    src={item.imageUrl}
                    alt={item.name}
                    className="w-[180px] h-[120px] rounded-xl"
                  />
                  <div className="flex flex-col gap-2">
                    <div>
                      <p className="MediumFont text-xl">{item.name}</p>
                      <p className="MediumFont text-xl">
                        €{item.price.toFixed(2)}
                      </p>
                    </div>
                    <div className="flex gap-1 flex-col">
                      <p className="text-base NormalFont text-[#696969]">
                        Qty: {item.quantity}
                      </p>
                      <p className="text-base NormalFont text-red-500">
                        Total: €{(item.price * item.quantity).toFixed(2)}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <form onSubmit={coupenCodeDiscount}>
              <input
                type="text"
                className={`w-full border p-1 rounded-[5px] h-[40px] pl-2 outline-none`}
                placeholder="Coupoun code"
                value={couponCode}
                onChange={(e) => setCouponCode(e.target.value)}
                required
              />
              <input
                className={`w-full h-[40px] border border-[#f63b60]  text-center text-[#f63b60] rounded-[3px] mt-8 cursor-pointer`}
                required
                value="Apply code"
                type="submit"
              />
            </form>

            <div className="flex flex-col gap-3">
              <div className="flex justify-between">
                <p className="text-[#191919] text-xl MediumFont">Sub Total</p>
                <p className="text-[#191919] text-xl MediumFont">
                  €{subTotal.toFixed(2)}
                </p>
              </div>
              <div className="flex justify-between">
                <p className="text-[#191919] text-xl MediumFont">
                  Delivery Charges
                </p>
                <p className="text-[#191919] text-xl MediumFont">
                  €{deliveryCharges.toFixed(2)}
                </p>
              </div>
              <div className="flex justify-between">
                <p className="text-[#191919] text-xl MediumFont">
                  Other Charges
                </p>
                <p className="text-[#191919] text-xl MediumFont">
                  €{otherCharges.toFixed(2)}
                </p>
              </div>
              <div className="flex justify-between border-b pb-3">
                <p className="text-[#191919] text-xl MediumFont">Discount</p>
                <h5 className="text-[18px] font-[600]">
                  -{" "}
                  {discountPercentenge
                    ? "€" + discountPercentenge.toString()
                    : null}
                </h5>
              </div>
            </div>

            <div className="py-2 ">
              <div className="flex justify-between">
                <p className="text-[#191919] text-2xl MediumFont">Total</p>
                <p className="text-[#191919] text-2xl MediumFont">€{total}</p>
              </div>
            </div>
            {selectedPayment === "cashOnDelivery" ? (
              <div className="w-full flex items-center justify-center">
                <button
                  className="bg-[#ACE03A] text-white font-bold text-base py-3 px-6 rounded-lg w-full"
                  onClick={handleSubmit}
                  disabled={loading}
                >
                  Proceed with the payment
                </button>
              </div>
            ) : null}
          </div>
        </div>
      </div>

      <AddressModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSave={handleAddressSave}
        address={address}
        setAddress={setAddress}
      />
    </>
  );
}

export default OrderDelivery;
