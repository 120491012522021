// src/pages/Shipping.jsx
import React from "react";

const Shipping = () => {
  const handleSubmit = (e) => {
    e.preventDefault();
    alert("Shipping details submitted successfully!");
  };

  return (
    <div className="min-h-screen bg-gray-100 py-8 px-4">
      <div className="max-w-4xl mx-auto bg-white shadow-md rounded-md p-6">
        <h1 className="text-2xl font-bold text-center mb-6">Shipping Details</h1>
        <form onSubmit={handleSubmit}>
          {/* Shipping Address */}
          <div className="mb-6">
            <h2 className="text-lg font-semibold mb-4">Shipping Address</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <input
                type="text"
                placeholder="First Name"
                className="border rounded-md px-4 py-2 focus:outline-none focus:ring focus:ring-blue-200"
                required
              />
              <input
                type="text"
                placeholder="Last Name"
                className="border rounded-md px-4 py-2 focus:outline-none focus:ring focus:ring-blue-200"
                required
              />
              <input
                type="email"
                placeholder="Email Address"
                className="border rounded-md px-4 py-2 focus:outline-none focus:ring focus:ring-blue-200"
                required
              />
              <input
                type="tel"
                placeholder="Phone Number"
                className="border rounded-md px-4 py-2 focus:outline-none focus:ring focus:ring-blue-200"
                required
              />
              <input
                type="text"
                placeholder="Address Line 1"
                className="col-span-1 md:col-span-2 border rounded-md px-4 py-2 focus:outline-none focus:ring focus:ring-blue-200"
                required
              />
              <input
                type="text"
                placeholder="Address Line 2 (Optional)"
                className="col-span-1 md:col-span-2 border rounded-md px-4 py-2 focus:outline-none focus:ring focus:ring-blue-200"
              />
              <input
                type="text"
                placeholder="City"
                className="border rounded-md px-4 py-2 focus:outline-none focus:ring focus:ring-blue-200"
                required
              />
              <input
                type="text"
                placeholder="State"
                className="border rounded-md px-4 py-2 focus:outline-none focus:ring focus:ring-blue-200"
                required
              />
              <input
                type="text"
                placeholder="Postal Code"
                className="border rounded-md px-4 py-2 focus:outline-none focus:ring focus:ring-blue-200"
                required
              />
              <input
                type="text"
                placeholder="Country"
                className="border rounded-md px-4 py-2 focus:outline-none focus:ring focus:ring-blue-200"
                required
              />
            </div>
          </div>

          {/* Shipping Method */}
          <div className="mb-6">
            <h2 className="text-lg font-semibold mb-4">Shipping Method</h2>
            <div className="space-y-3">
              <label className="flex items-center space-x-2">
                <input
                  type="radio"
                  name="shippingMethod"
                  value="standard"
                  className="form-radio"
                  required
                />
                <span>Standard Shipping (5-7 business days) - $5.00</span>
              </label>
              <label className="flex items-center space-x-2">
                <input
                  type="radio"
                  name="shippingMethod"
                  value="express"
                  className="form-radio"
                />
                <span>Express Shipping (2-3 business days) - $10.00</span>
              </label>
              <label className="flex items-center space-x-2">
                <input
                  type="radio"
                  name="shippingMethod"
                  value="overnight"
                  className="form-radio"
                />
                <span>Overnight Shipping (1 business day) - $20.00</span>
              </label>
            </div>
          </div>

          {/* Order Summary */}
          <div className="mb-6">
            <h2 className="text-lg font-semibold mb-4">Order Summary</h2>
            <div className="border rounded-md p-4 bg-gray-50">
              <p className="flex justify-between">
                <span>Subtotal:</span>
                <span>$50.00</span>
              </p>
              <p className="flex justify-between">
                <span>Shipping:</span>
                <span>$5.00</span>
              </p>
              <p className="flex justify-between font-bold">
                <span>Total:</span>
                <span>$55.00</span>
              </p>
            </div>
          </div>

          {/* Submit Button */}
          <button
            type="submit"
            className="w-full bg-blue-500 text-white font-semibold py-2 rounded-md hover:bg-blue-600 transition"
          >
            Proceed to Payment
          </button>
        </form>
      </div>
    </div>
  );
};

export default Shipping;
