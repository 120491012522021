import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchOrders } from "../../redux/slices/orderSlice";
import { Link } from "react-router-dom";
import { AiOutlineArrowRight } from "react-icons/ai";
import search from "../../assets/images/search-lg.png";
import arrowdown from "../../assets/images/arrowdowngray.png";
import ReactPaginate from "react-paginate";
import "./RecentOrders.css";
import { RotatingLines } from 'react-loader-spinner';

function RecentOrders() {
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCheckboxes, setSelectedCheckboxes] = useState({});
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 8;
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false); 

  const dispatch = useDispatch();
  const { orders, status } = useSelector((state) => state.orders);

  // useEffect(() => {
  //   dispatch(fetchOrders());
  // }, [dispatch]);

  useEffect(() => {
    setLoading(true); 
    dispatch(fetchOrders())
      .finally(() => setLoading(false)); 
  }, [dispatch]);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };


  const filteredOrders = orders.filter((order) => {
    const idMatch =
      order?._id && order._id.toLowerCase().includes(searchQuery.toLowerCase());
    const nameMatch =
      order?.shippingAddress?.Address?.fullName &&
      order.shippingAddress.Address.fullName
        .toLowerCase()
        .includes(searchQuery.toLowerCase());
    const cartMatch =
      order?.cart?.name &&
      order.cart.name.toLowerCase().includes(searchQuery.toLowerCase());

    return idMatch || nameMatch || cartMatch;
  });

  if (loading) {
    return (
      <div className="flex justify-center items-center ">
       <RotatingLines
              height="80"
              width="80"
              color="#003E00"
              ariaLabel="loading"
            />
      </div>
    );
  }

  const pageCount = Math.ceil(filteredOrders.length / itemsPerPage);
  const offset = currentPage * itemsPerPage;
  const currentItems = filteredOrders.slice(offset, offset + itemsPerPage);

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

  return (
    <div className="md:px-10 px-8">
      <div>
        <p className="text-[#475467] text-xl quicksand-Bold md:my-4 my-3">
          Recent Orders
        </p>
      </div>
      <div className="flex md:flex-row flex-col justify-between items-center gap-4">
        <div>
          <div className="border border-gray-300 flex p-3 rounded-lg gap-2 items-center">
            <img src={search} alt="Search icon" />
            <input
              type="text"
              placeholder="Search"
              className="md:w-[280px] outline-none"
              value={searchQuery}
              onChange={handleSearchChange}
            />
          </div>
        </div>
      </div>

      <div className="my-10">
        <div className="overflow-x-auto">
          <table
            style={{
              width: "100%",
              borderCollapse: "collapse",
              border: "1px solid #EAECF0",
              borderRadius: "20px",
            }}
          >
            <thead className="bg-gray-50">
              <tr className="border-b">
                <th
                  style={{
                    textAlign: "left",
                    display: "flex",
                    gap: "12px",
                    alignItems: "center",
                    padding: "12px 20px",
                  }}
                >
                  <p className="text-[#475467] inter-Medium text-xs">
                    Order No.
                  </p>
                </th>
                <th style={{ textAlign: "left", padding: "12px 20px" }}>
                  <p className="text-[#475467] inter-Medium text-xs">
                    Customer Name
                  </p>
                </th>
                <th style={{ textAlign: "left", padding: "12px 20px" }}>
                  <p className="text-[#475467] inter-Medium text-xs">
                    Order Total
                  </p>
                </th>
                <th style={{ textAlign: "left", padding: "12px 20px" }}>
                  <p className="text-[#475467] inter-Medium text-xs">Status</p>
                </th>
                <th style={{ textAlign: "left", padding: "12px 20px" }}></th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((order, index) => (
                <tr key={order._id} className="border-b">
                  <td
                    style={{
                      padding: "16px 20px",
                      display: "flex",
                      gap: "12px",
                      alignItems: "center",
                    }}
                  >
                    <p className="text-[#101828] inter-Medium text-sm">
                      {String(offset + index + 1).padStart(6, "0")}
                    </p>
                  </td>
                  <td style={{ padding: "16px 20px" }}>
                    <p className="text-[#475467] inter-Regular text-sm">
                      {order.shippingAddress.Address.fullName}
                    </p>
                  </td>
                  <td style={{ padding: "16px 20px" }}>
                    <p className="text-[#475467] inter-Regular text-sm">
                      {order.cart.length}
                    </p>
                  </td>
                  <td style={{ padding: "16px 20px" }}>
                    <div className="bg-[#ECFDF3] rounded-xl w-[80px]">
                      <p className="inter-Medium bg-[#ECFDF3] p-2 rounded-xl text-xs text-[#027A48]">
                        {order.status}
                      </p>
                    </div>
                  </td>
                  <td style={{ padding: "16px 20px" }}>
                    <Link to={`order-detail/${order._id}`}>
                      <button>
                        <AiOutlineArrowRight size={20} />
                      </button>
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <ReactPaginate
          previousLabel={"Previous"}
          nextLabel={"Next"}
          pageCount={pageCount}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          activeClassName={"active"}
          previousClassName={"page-item"}
          nextClassName={"page-item"}
          breakClassName={"page-item"}
          pageClassName={"page-item"}
          pageLinkClassName={"page-link"}
          disabledClassName={"disabled"}
        />
      </div>
    </div>
  );
}

export default RecentOrders;
