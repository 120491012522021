import React, { useState, useEffect } from 'react';
import flagicon from "../../assets/images/flagicon.png";
import xclose from "../../assets/images/x-close.png";
import upload from "../../assets/images/upload-cloud.png";
import "../../index.css";
import { ENDPOINTS } from '../../api/apiRoutes';
import API from '../../api/apiService';
import { toast } from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css'; 

function EditBannerModal({ handleCloseModal, bannerId, token, bannerData, onUpdateBanner }) {
  const [selectedBanner, setSelectedBanner] = useState('Top Banner');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [isActive, setIsActive] = useState(true);
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setSelectedBanner(bannerData.bannerType);
    setStartDate(bannerData.startDate);
    setEndDate(bannerData.endDate);
    setIsActive(bannerData.isActive);
    setImagePreview(bannerData.image || null); 
  }, [bannerData]);

  const handleBannerChange = (event) => {
    setSelectedBanner(event.target.value);
  };

  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
  };

  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
  };

  const handleToggle = () => {
    setIsActive(!isActive);
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImage(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result); 
      };
      reader.readAsDataURL(file);
    }
  };

  const handleUpdateBanner = async () => {
    setLoading(true);
    const formData = new FormData();
    formData.append('bannerType', selectedBanner);
    formData.append('startDate', startDate);
    formData.append('endDate', endDate);
    formData.append('isActive', isActive);
    if (image) {
      formData.append('image', image);
    }

    try {
      const response = await API.put(
        `${ENDPOINTS.UPDATE_BANNER}/${bannerId}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${token}`,
          },
        }
      );
        toast.success("Banner updated successfully!");
        onUpdateBanner(response.banner); 
        handleCloseModal();
      }
    catch (error) {
      toast.error("Error updating banner. Please try again!");
      console.error("Error updating banner:", error);
    } finally {
      setLoading(false); 
    }
  };

  return (
    <>
      <div className="fixed inset-0  bg-gray-300 bg-opacity-50 backdrop-blur-sm z-40"></div>

      <div className='fixed inset-0  flex items-center justify-center z-50'>
        <div className='px-6 py-2  border-2 rounded-md w-auto bg-white'>
          <div className='flex justify-between mb-2'>
            <div className="p-2 border border-gray-300 rounded-md">
              <img src={flagicon} alt="Flag Icon" />
            </div>
            <div>
              <img 
                src={xclose} 
                alt="Close Icon" 
                onClick={handleCloseModal}
                className="cursor-pointer"
              />
            </div>
          </div>

          <div className='mb-2'>
            <label className='block text-sm text-[#344054] mb-1'>Select Banner Type:</label>
            <select 
              value={selectedBanner} 
              onChange={handleBannerChange} 
              className='border border-gray-300 rounded-md p-2 w-full'
            >
              <option value="Top Banner">Top Banner</option>
              <option value="Middle Banner">Middle Banner</option>
            </select>
          </div>

          <div className='mb-2'>
            <label className='block text-sm text-[#344054] mb-1'>Start Date:</label>
            <input 
              type="date" 
              value={startDate} 
              onChange={handleStartDateChange} 
              className='border border-gray-300 rounded-md p-2 w-full'
            />
          </div>

          <div className='mb-2'>
            <label className='block text-sm text-[#344054] mb-1'>End Date:</label>
            <input 
              type="date" 
              value={endDate} 
              onChange={handleEndDateChange} 
              className='border border-gray-300 rounded-md p-2 w-full'
            />
          </div>

          <div className='flex flex-col gap-2'>
            <p className='text-[#344054] MediumFont text-sm'>Banner Image</p>
            <div className='flex flex-col items-center justify-center gap-4 border border-gray-300 rounded-md p-4'>
              
              <div className='w-[300px] flex flex-col justify-center items-center'>
              {imagePreview && (
                  <img 
                    src={imagePreview} 
                    alt="Selected" 
                    className="mt-2 w-full h-full object-cover rounded-md" 
                  />
                )}
                <input 
                  type="file" 
                  accept="image/*" 
                  onChange={handleImageUpload} 
                  className='mt-2 w-full text-sm text-gray-500'
                />
               
              </div>
            </div>
          </div>

          <div className='mt-2 flex items-center justify-between'>
            <p className='text-[#344054] MediumFont text-sm'>Status:</p>
            <div className='flex items-center'>
              <label className='relative inline-block w-10 h-6'>
                <input 
                  type="checkbox" 
                  checked={isActive} 
                  onChange={handleToggle} 
                  className="sr-only peer"
                />
                <div className="w-full h-full bg-gray-300 rounded-full peer-checked:bg-[#003E00]"></div>
                <div className="absolute top-1 left-1 peer-checked:left-5 w-4 h-4 bg-white rounded-full transition-all"></div>
              </label>
              <span className='ml-2 text-sm'>
                {isActive ? 'Active' : 'Not Active'}
              </span>
            </div>
          </div>

          <div className='flex gap-4 items-center justify-center mt-6'>
            <button 
              onClick={handleCloseModal}
              className="text-[#003E00] text-sm inter-Semi bg-[#F9FFF5] py-2 px-10 rounded-lg"
            >
              Cancel
            </button>
            <button 
              onClick={handleUpdateBanner}
              disabled={loading} 
              className={`bg-[#003E00] text-sm inter-Semi text-white py-2 px-10 rounded-lg ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
            >
              {loading ? 'Updating...' : 'Update Banner'}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditBannerModal;

