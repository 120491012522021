import React, { useState, useEffect } from "react";
import { CiUser } from "react-icons/ci";
import { RiArrowDropDownLine, RiCloseLine } from "react-icons/ri";
import { useDispatch } from "react-redux";
import { logout } from "../redux/slices/loginSlice";
import { useNavigate } from "react-router-dom";

function Header({ toggleSidebar }) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [username, setUsername] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const storedUsername = JSON.parse(localStorage.getItem("userName"));
    if (storedUsername) {
      setUsername(storedUsername);
    }
  }, []);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleLogout = () => {
    dispatch(logout());
    localStorage.removeItem("token");
    localStorage.removeItem("userName");
    navigate("/login");
  };

  return (
    <div className="py-[24px] px-[16px] sm:px-[32px] flex items-center justify-between">
      <div className="flex gap-[16px] sm:gap-[32px] items-center">
        <button className="sm:hidden" onClick={toggleSidebar}>
          &#9776;
        </button>
      </div>

      <div className="relative  hidden sm:flex gap-[16px] items-center">
        <div className="flex gap-[10px] items-center">
          <CiUser size={32} />
          <p className="MediumFont text-sm text-[#344054] font-sans">
            {username || "User"}
          </p>
          <button onClick={toggleDropdown}>
            <RiArrowDropDownLine size={32} />
          </button>
        </div>

        {isDropdownOpen && (
          <div className="absolute  right-0 mt-[70px] w-[200px] bg-white border border-gray-200 rounded-md shadow-lg z-10">
            <div className="flex justify-end px-2">
              <button onClick={() => setIsDropdownOpen(false)}>
                <RiCloseLine size={24} />
              </button>
            </div>
            <div className="px-2 py-2">
              <p className="font-semibold text-sm flex items-center gap-2 text-[#344054]">
                <CiUser size={32} />
                {username || "User"}
              </p>
            </div>
            <div className="border-t border-gray-200">
              <button
                className="w-full text-left px-2 py-2 text-md font-semibold text-red-600 hover:bg-gray-100"
                onClick={handleLogout}
              >
                Logout
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Header;
