import { React, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import loginImage from "../../../../assets/images/login.png";
import "../../../../index.css";
import API from "../../../../api/apiService";
import { ENDPOINTS } from "../../../../api/apiRoutes";
import { useDispatch } from "react-redux";
import { login } from "../../../../redux/slices/loginSlice";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import { IoEyeOffOutline, IoEyeOutline } from "react-icons/io5";

function Login() {
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    password: Yup.string()
      .min(8, "Password must be at least 8 characters")
      .required("Password is required"),
  });

  const handleLoginSubmit = async (values, { setSubmitting }) => {
    try {
      const response = await API.post(ENDPOINTS.LOGIN, values);

      if (response.token) {
        const userRole = response.role;
        const userName = response.username;
        const email = values.email;
        const _id = response._id;
    

        localStorage.setItem("token", response.token);
        localStorage.setItem("userName", JSON.stringify(userName));
        localStorage.setItem("email", email); 
        localStorage.setItem("_id", _id); 
        
       

        dispatch(
          login({
            user: { email: values.email, name: userName, id:_id },
            isAdmin: userRole === "admin",
          })
        );

        toast.success("Send Otp Successful!");

        // Redirect based on role
        setTimeout(() => {
          if (userRole === "admin") {
            navigate("/verification");
          } else {
            navigate("/");
          }
        }, 1000);
      } else {
        toast.error(response.message || "Send Otp failed.");
      }
    } catch (err) {
      toast.error("Provide valid email and password. Please try again.");
      console.error(err);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="w-[100%]">
      <div className="md:flex justify-center">
        <div className="md:w-[50%] md:h-[100vh] bg-[#004D00]  flex justify-between flex-col ">
          <div className="p-4">
            <IoArrowBackCircleOutline
              size={60}
              color="white"
              onClick={() => navigate("/")}
              className="cursor-pointer"
            />
          </div>

          <div>
            <img src={loginImage} alt="product" className="" />
          </div>
        </div>
        <div className="md:w-[50%] rounded-2xl flex flex-col justify-center bg-white items-center md:mt-0 mt-5">
          <div>
            <h1 className="md:text-4xl text-3xl font-bold">Gets Started!</h1>
            <p className="text-xl font-semibold mt-4 mb-6">
              Login to your account
            </p>

            <Formik
              initialValues={{ email: "", password: "" }}
              validationSchema={validationSchema}
              onSubmit={handleLoginSubmit}
            >
              {({ isSubmitting }) => (
                <Form className="flex flex-col gap-2 md:w-[450px]">
                  <div className="flex flex-col">
                    <label className="font-semibold">Email</label>
                    <Field
                      type="email"
                      name="email"
                      placeholder="Enter your Email"
                      className="border-2 border-gray-400 rounded-md w-full p-2 outline-none"
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="text-red-500"
                    />
                  </div>

                  <div className="flex flex-col relative">
                    <label className="md:mt-4 mt-2 font-semibold">
                      Password
                    </label>
                    <Field
                      type={showPassword ? "text" : "password"}
                      name="password"
                      placeholder="Enter your Password"
                      className="border-2 border-gray-400 rounded-md w-full p-2 outline-none"
                    />
                    <ErrorMessage
                      name="password"
                      component="div"
                      className="text-red-500"
                    />
                    <div
                      className="absolute right-3 md:top-12 top-10 cursor-pointer"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? (
                        <IoEyeOutline size={24} />
                      ) : (
                        <IoEyeOffOutline size={24} />
                      )}
                    </div>
                  </div>

                  <div className="flex justify-between mt-2 gap-3">
                    <div className="flex gap-1">
                      <Field
                        type="checkbox"
                        name="rememberMe"
                        className="self-center"
                      />
                      <p className="font-semibold">Remember me</p>
                    </div>
                  
                      <button onClick={() => navigate("/forgotpass")} className="font-semibold underline cursor-pointer">
                        Forgot Password?
                      </button>
             
                  </div>

                  <button
                    type="submit"
                    className="w-full py-[10px] px-3 bg-[#004D00] text-white rounded-xl text-[16px] mt-6 inter-Semi"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? "Logging in..." : "Login"}
                  </button>
                </Form>
              )}
            </Formik>

            <p className="font-semibold text-center mt-8">
              Don't have an account?{" "}
              <button
                className="text-[#004D00]"
                onClick={() => navigate("/register")}
              >
                Signup!
              </button>
            </p>
          </div>
          <p className="text-gray-400 relative top-8 font-light">
            © 2024 Designed by Oliver | All rights reserved
          </p>
        </div>
      </div>
    </div>
  );
}

export default Login;
