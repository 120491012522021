import React from "react";
import profile from "../../../assets/images/Content.png";
function Profile() {
  return (
    <>
      <div className="">
        <div className="min-h-[200px] bg-gradient-to-r from-green-800 via-green-700 to-green-600 md:block hidden"></div>
        <div className="md:flex justify-between relative bottom-10 px-8 border-b ">
          <div className="flex gap-6">
            <div>
              {" "}
              <img src={profile} alt="profile" />
            </div>
            <div className="self-center">
              <p className="text-3xl font-bold">Oliver Rey</p>
              <p className="text-xl">Update your photo and personal details.</p>
            </div>
          </div>
          <div className="self-center flex gap-4">
            <button className="text-[#003E00] px-6 py-2 rounded-xl font-semibold   bg-gray-50">
              Cancel
            </button>
            <button className=" bg-[#003E00] py-2 text-white rounded-xl font-semibold px-6">
              Save
            </button>
          </div>
        </div>
        <div className="p-8 flex flex-col gap-6 md:w-[800px] border-b">
          <div className="md:flex ">
            <label className="w-[300px] font-semibold self-center">
              User Name
            </label>
            <input
              type="text"
              className="border w-full py-3 px-4 rounded-lg"
              placeholder="User Name"
            />
          </div>
          <div className="md:flex ">
            <label className="w-[300px] font-semibold self-center">
              Full Name
            </label>
            <input
              type="text"
              className="border w-full py-3 px-4 rounded-lg"
              placeholder="Full Name"
            />
          </div>
          <div className="flex justify-between border-t py-4 border-b">
            <div>
              <h2 className="font-semibold">Your Photo</h2>
              <p>this will be displayed on your profile</p>
            </div>
            <div>
              <img src={profile} alt="img" className="w-[60px]" />
            </div>
            <div className="flex gap-4 self-start">
              <button className="font-semibold">Delete</button>
              <button className="font-semibold text-blue-400">Update</button>
            </div>
          </div>
          <div className="md:flex ">
            <label className="w-[300px] font-semibold self-center">Email</label>
            <input
              type="email"
              className="border w-full py-3 px-4 rounded-lg"
              placeholder="Email..."
            />
          </div>
          <div className="md:flex ">
            <label className="w-[300px] font-semibold self-center">
              Password
            </label>
            <input
              type="password"
              className="border w-full py-3 px-4 rounded-lg"
              placeholder="Password..."
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Profile;
