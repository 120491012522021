import React, { useEffect } from 'react'
import Product from '../../components/Product/Product';
import Navbar from '../../components/Navbar/Navbar';
import PeopleBuy from '../../components/PeopleBuy/PeopleBuy';
import Footer from '../../components/Footer/Footer';

function ProductPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); 
  return (
    <>
   <Navbar />
    <Product />
    <PeopleBuy />
    <Footer />
      
    </>
  )
}

export default ProductPage
