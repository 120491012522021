import React from "react";
import img1 from "../../assets/images/EC.jpeg";
import img2 from "../../assets/images/ec2.jpeg";
import img3 from "../../assets/images/ec3.jpeg";
import img4 from "../../assets/images/arrow_outward.png";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

function Collection() {
  const state = useSelector((state) => state.categories);

  return (
    <>
      <div className="flex md:gap-[30px] md:py-[24px] py-[12px] md:px-[32px] px-[16px] mb-10 mt-10 md:flex-row flex-col items-center justify-center gap-6">
        <div
          className="md:w-[45%] w-full   h-[416px] rounded-3xl bg-cover  p-3 flex justify-between "
          style={{ backgroundImage: `url(${img1})`, width: "100%" }}
        >
          <div className="flex items-end ">
            <div className="md:h-[40px] w-auto px-4 py-2 bg-white rounded-3xl flex items-center justify-center">
              <p className="md:text-base text-sm MediumFont">Fruits</p>
            </div>
          </div>

          <div className="flex  justify-end ">
            <Link
               to={`/orderPlacement?name=Fruits%20&%20Veggies&id=6709010fb2b88c19e1723f3f`}
              className="h-[44px] w-auto px-4 py-2 bg-white rounded-3xl flex items-center justify-center gap-2 cursor-pointer"
            >
              <p className="md:text-base text-xs MediumFont">Explore Collection</p>
              <img src={img4} alt="" />
            </Link>
          </div>
        </div>
        <div
          className="md:w-[25%] w-full h-[416px] rounded-3xl bg-cover  p-3 flex justify-between"
          style={{ backgroundImage: `url(${img2})`, width: "100%" }}
        >
          <div className="flex items-end ">
            <div className="md:w-[78px] h-[40px] w-auto px-4 py-2 bg-white rounded-3xl flex items-center justify-center">
              <p className="md:text-base text-sm  MediumFont">Veggies</p>
            </div>
          </div>

          <div className="flex  justify-end ">
            <Link
             to={`/orderPlacement?name=Fruits%20&%20Veggies&id=6709010fb2b88c19e1723f3f`}
              className="h-[44px] w-auto px-4 py-2 bg-white rounded-3xl flex items-center justify-center gap-2 cursor-pointer"
            >
              <p className="md:text-base text-xs MediumFont">Explore Collection</p>
              <img src={img4} alt="" />
            </Link>
          </div>
        </div>
        <div
          className="md:w-[25%] w-full h-[416px] rounded-3xl bg-cover  p-3 flex justify-between"
          style={{ backgroundImage: `url(${img3})`, width: "100%" }}
        >
          <div className="flex items-end ">
            <div className="md:w-[78px] h-[40px] w-auto px-4 py-2 bg-white rounded-3xl flex items-center justify-center">
              <p className="md:text-base text-sm  MediumFont">Spices</p>
            </div>
          </div>

          <div className="flex  justify-end ">
            <Link
              to={`/orderPlacement?name=Whole%20Spices&id=6708fd1ab2b88c19e17212f1`}
              className="h-[44px] w-auto px-4 py-2 bg-white rounded-3xl flex items-center justify-center gap-2 cursor-pointer"
            >
              <p className="md:text-base text-xs MediumFont">Explore Collection</p>
              <img src={img4} alt="" />
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default Collection;
