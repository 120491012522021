import React from "react";
import Navbar from "../../../components/Navbar/Navbar";
import TotalOrders from "../../../components/TotalOrders/TotalOrders";
import RecentOrders from "../../../components/RecentOrders/RecentOrders";

function Orders() {
  return (
    <>
      <TotalOrders />
      <RecentOrders />
    </>
  );
}

export default Orders;
