import { createSlice } from "@reduxjs/toolkit";

const loadWishlistFromLocalStorage = () => {
  try {
    const serializedState = localStorage.getItem("wishlist");
    return serializedState ? JSON.parse(serializedState) : [];
  } catch (e) {
    console.error("Could not load wishlist from localStorage", e);
    return [];
  }
};

const saveWishlistToLocalStorage = (wishlist) => {
  try {
    const serializedState = JSON.stringify(wishlist);
    localStorage.setItem("wishlist", serializedState);
  } catch (e) {
    console.error("Could not save wishlist to localStorage", e);
  }
};

const initialState = {
  items: loadWishlistFromLocalStorage(),
};

const wishlistSlice = createSlice({
  name: "wishlist",
  initialState,
  reducers: {
    addToWishlist: (state, action) => {
      const itemExists = state.items.find(
        (item) => item._id === action.payload._id
      );
      if (!itemExists) {
        state.items.push(action.payload);
        saveWishlistToLocalStorage(state.items);
      }
    },
    removeFromWishlist: (state, action) => {
      state.items = state.items.filter((item) => item._id !== action.payload);
      saveWishlistToLocalStorage(state.items);
    },
  },
});

export const { addToWishlist, removeFromWishlist } = wishlistSlice.actions;

export const selectWishlistItems = (state) => state.wishlist.items;

export const isProductInWishlist = (productId) => (state) =>
  state.wishlist.items.some((item) => item._id === productId);

export default wishlistSlice.reducer;
