import React, { useEffect } from "react";
import { FaUsers, FaRegHandshake } from "react-icons/fa"; // Icons for team and mission

function About() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="min-h-screen bg-gray-100 py-8 px-4">
      <div className="max-w-6xl mx-auto bg-white p-6 rounded-lg shadow-lg">
        {/* Section 1: Introduction */}
        <div className="text-center mb-12">
          <h1 className="text-4xl font-semibold text-gray-800">About Us</h1>
          <p className="mt-4 text-lg text-gray-600">
            We are an innovative eCommerce platform dedicated to bringing you the
            best products at the best prices. Our mission is to create a seamless
            shopping experience and deliver quality to our customers.
          </p>
        </div>

        {/* Section 2: Our Mission */}
        <div className="flex items-center justify-center mb-12">
          <div className="w-full md:w-1/2 text-center p-6 bg-gray-200 rounded-lg shadow-md">
            <FaRegHandshake className="text-4xl text-blue-500 mx-auto" />
            <h2 className="text-2xl font-semibold text-gray-700 mt-4">Our Mission</h2>
            <p className="text-lg text-gray-600 mt-4">
              Our mission is to provide an unparalleled shopping experience that
              combines convenience, affordability, and customer satisfaction. We are
              committed to offering a wide variety of high-quality products to suit
              every need.
            </p>
          </div>
        </div>

        {/* Section 3: Our Team */}
        <div className="mb-12 text-center">
          <h2 className="text-2xl font-semibold text-gray-800">Meet Our Team</h2>
          <p className="text-lg text-gray-600 mt-4">
            Our team consists of passionate individuals dedicated to ensuring
            your shopping experience is nothing short of excellent.
          </p>
          <div className="mt-8 grid grid-cols-1 md:grid-cols-3 gap-8">
            {/* Team Member 1 */}
            <div className="bg-gray-100 p-6 rounded-lg shadow-md">
              <div className="flex items-center justify-center mb-4">
                <div className="w-24 h-24 bg-blue-500 rounded-full flex items-center justify-center text-white text-3xl">
                  A
                </div>
              </div>
              <h3 className="text-xl font-semibold text-gray-800">Alice Johnson</h3>
              <p className="text-gray-600 mt-2">CEO & Founder</p>
            </div>
            {/* Team Member 2 */}
            <div className="bg-gray-100 p-6 rounded-lg shadow-md">
              <div className="flex items-center justify-center mb-4">
                <div className="w-24 h-24 bg-blue-500 rounded-full flex items-center justify-center text-white text-3xl">
                  B
                </div>
              </div>
              <h3 className="text-xl font-semibold text-gray-800">Bob Williams</h3>
              <p className="text-gray-600 mt-2">Lead Developer</p>
            </div>
            {/* Team Member 3 */}
            <div className="bg-gray-100 p-6 rounded-lg shadow-md">
              <div className="flex items-center justify-center mb-4">
                <div className="w-24 h-24 bg-blue-500 rounded-full flex items-center justify-center text-white text-3xl">
                  C
                </div>
              </div>
              <h3 className="text-xl font-semibold text-gray-800">Charlie Smith</h3>
              <p className="text-gray-600 mt-2">Head of Marketing</p>
            </div>
          </div>
        </div>

        {/* Section 4: Why Choose Us */}
        <div className="mb-12 text-center">
          <h2 className="text-2xl font-semibold text-gray-800">Why Choose Us?</h2>
          <p className="text-lg text-gray-600 mt-4">
            We believe in providing the best service possible. Here's why we're the
            right choice for you:
          </p>
          <div className="mt-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {/* Reason 1 */}
            <div className="bg-gray-100 p-6 rounded-lg shadow-md">
              <h3 className="text-xl font-semibold text-gray-800">Wide Selection</h3>
              <p className="text-gray-600 mt-2">
                We offer a wide variety of products to meet all your needs, from electronics to fashion.
              </p>
            </div>
            {/* Reason 2 */}
            <div className="bg-gray-100 p-6 rounded-lg shadow-md">
              <h3 className="text-xl font-semibold text-gray-800">Fast Shipping</h3>
              <p className="text-gray-600 mt-2">
                Our fast shipping service ensures you get your order delivered in no time.
              </p>
            </div>
            {/* Reason 3 */}
            <div className="bg-gray-100 p-6 rounded-lg shadow-md">
              <h3 className="text-xl font-semibold text-gray-800">Customer Satisfaction</h3>
              <p className="text-gray-600 mt-2">
                We value our customers and ensure that you're fully satisfied with every purchase.
              </p>
            </div>
          </div>
        </div>

        {/* Footer */}
        <div className="text-center">
          <p className="text-gray-600">
            © 2024 Your eCommerce Website | All Rights Reserved
          </p>
        </div>
      </div>
    </div>
  );
}

export default About;
