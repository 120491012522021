import React, { useState, useEffect } from "react";
import { FaUserAlt, FaLock, FaBell } from "react-icons/fa"; 
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Setting() {
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    password: "",
    notifications: true,
    emailNotifications: true,
  });

  useEffect(() => {
    const storedUsername = JSON.parse(localStorage.getItem("userName"));
    const storedEmail = localStorage.getItem("email");

    if (storedUsername) {
      setFormData((prevData) => ({
        ...prevData,
        username: storedUsername,
      }));
    }

    if (storedEmail) {
      setFormData((prevData) => ({
        ...prevData,
        email: storedEmail,
      }));
    }
  }, []);

  
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };


  const handleSave = () => {
    toast.success("Information saved successfully!", {

    });
  };

  return (
    <div className="min-h-screen bg-gray-100 py-8 px-4">
      <div className="max-w-3xl mx-auto bg-white p-6 rounded-lg shadow-lg">
        {/* Profile Settings Section */}
        <div className="mb-6">
          <h2 className="text-2xl font-semibold text-gray-700 flex items-center">
            <FaUserAlt className="mr-2 text-xl" /> Profile Settings
          </h2>
          <div className="mt-4 space-y-4">
            <div>
              <label className="text-gray-700 font-semibold">Username</label>
              <input
                type="text"
                name="username"
                value={formData.username}
                onChange={handleChange}
                readOnly 
                className="w-full p-2 mt-2 border border-gray-300 rounded-md outline-none"
              
              />
            </div>
            <div>
              <label className="text-gray-700 font-semibold">Email</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                readOnly 
                className="w-full p-2 mt-2 border border-gray-300 rounded-md outline-none"
            
              />
            </div>
            {/* <div>
              <label className="text-gray-700 font-semibold">Password</label>
              <input
                type="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                className="w-full p-2 mt-2 border border-gray-300 rounded-md"
              />
            </div> */}
          </div>
        </div>

        <div className="mb-6">
          <h2 className="text-2xl font-semibold text-gray-700 flex items-center">
            <FaBell className="mr-2 text-xl" /> Notification Settings
          </h2>
          <div className="mt-4 space-y-4">
            <div className="flex items-center">
              <input
                type="checkbox"
                name="notifications"
                checked={formData.notifications}
                onChange={handleChange}
                className="mr-2"
              />
              <label className="text-gray-700 font-semibold">Enable Notifications</label>
            </div>
            {formData.notifications && (
              <div className="flex items-center">
                <input
                  type="checkbox"
                  name="emailNotifications"
                  checked={formData.emailNotifications}
                  onChange={handleChange}
                  className="mr-2"
                />
                <label className="text-gray-700 font-semibold">Email Notifications</label>
              </div>
            )}
          </div>
        </div>

        <div className="mb-6">
          <h2 className="text-2xl font-semibold text-gray-700 flex items-center">
            <FaLock className="mr-2 text-xl" /> Account Settings
          </h2>
          <div className="mt-4 space-y-4">
            <div className="flex items-center">
              <input
                type="checkbox"
                name="enableTwoFactor"
                checked={formData.enableTwoFactor}
                onChange={handleChange}
                className="mr-2"
              />
              <label className="text-gray-700 font-semibold">Enable Two-Factor Authentication</label>
            </div>
          </div>
        </div>

        <div className="flex justify-end">
          <button
            onClick={handleSave}
            className="px-6 py-2 bg-[#ACE03A] text-white font-semibold rounded-md"
          >
            Save Changes
          </button>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default Setting;
