import React, { useState } from "react";
import { FaUserAlt } from "react-icons/fa"; // Importing user icon from react-icons

function ProfileEdit() {
  const [isEditing, setIsEditing] = useState(false);
  const [userData, setUserData] = useState({
    name: "John Doe",
    email: "john.doe@example.com",
    bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    avatar: null, // Initially set to null as we will use a profile icon
  });

  const handleEditToggle = () => {
    setIsEditing(!isEditing);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSave = () => {
    // Handle saving the profile changes (e.g., make API call to save data)
    setIsEditing(false);
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100 py-8">
      <div className="bg-white w-full max-w-md mx-auto p-6 rounded-lg shadow-lg">
        <div className="flex justify-center mb-4">
          {/* Profile Icon used instead of image */}
          <div className="w-32 h-32 flex justify-center items-center bg-gray-200 rounded-full">
            <FaUserAlt className="text-4xl text-gray-600" /> {/* Profile Icon */}
          </div>
        </div>

        <div className="text-center mb-6">
          <h1 className="text-2xl font-bold">{isEditing ? "Edit Profile" : "Profile"}</h1>
        </div>

        <div className="space-y-4">
          <div>
            <label htmlFor="name" className="block text-gray-700 font-semibold">
              Name
            </label>
            {isEditing ? (
              <input
                type="text"
                id="name"
                name="name"
                value={userData.name}
                onChange={handleChange}
                className="w-full p-2 mt-2 border border-gray-300 rounded-md"
              />
            ) : (
              <p className="text-gray-700">{userData.name}</p>
            )}
          </div>

          <div>
            <label htmlFor="email" className="block text-gray-700 font-semibold">
              Email
            </label>
            {isEditing ? (
              <input
                type="email"
                id="email"
                name="email"
                value={userData.email}
                onChange={handleChange}
                className="w-full p-2 mt-2 border border-gray-300 rounded-md"
              />
            ) : (
              <p className="text-gray-700">{userData.email}</p>
            )}
          </div>

          <div>
            <label htmlFor="bio" className="block text-gray-700 font-semibold">
              Bio
            </label>
            {isEditing ? (
              <textarea
                id="bio"
                name="bio"
                value={userData.bio}
                onChange={handleChange}
                rows="4"
                className="w-full p-2 mt-2 border border-gray-300 rounded-md"
              />
            ) : (
              <p className="text-gray-700">{userData.bio}</p>
            )}
          </div>
        </div>

        <div className="mt-6 flex justify-between items-center">
          {isEditing ? (
            <button
              onClick={handleSave}
              className="px-6 py-2 bg-blue-500 text-white font-semibold rounded-md"
            >
              Save Changes
            </button>
          ) : (
            <button
              onClick={handleEditToggle}
              className="px-6 py-2 bg-green-500 text-white font-semibold rounded-md"
            >
              Edit Profile
            </button>
          )}

          <button
           
            className="px-6 py-2 bg-red-500 text-white font-semibold rounded-md"
          >
            Logout
          </button>
        </div>
      </div>
    </div>
  );
}

export default ProfileEdit;
