import React, { useEffect } from "react";

function PrivacyPolicy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="min-h-screen bg-gray-100 py-8 px-4">
      <div className="max-w-6xl mx-auto bg-white p-6 rounded-lg shadow-lg">
        {/* Section 1: Title and Introduction */}
        <div className="text-center mb-12">
          <h1 className="text-4xl font-semibold text-gray-800">Privacy Policy</h1>
          <p className="mt-4 text-lg text-gray-600">
            Your privacy is important to us. This Privacy Policy explains how we collect, use, and protect your personal information.
          </p>
        </div>

        {/* Section 2: Information We Collect */}
        <div className="mb-12">
          <h2 className="text-2xl font-semibold text-gray-800">Information We Collect</h2>
          <p className="mt-4 text-lg text-gray-600">
            We collect various types of information to provide a better shopping experience. The information we collect may include:
          </p>
          <ul className="mt-4 list-disc pl-6 text-gray-600">
            <li>Personal information such as your name, email address, and payment details.</li>
            <li>Transaction history and purchase details.</li>
            <li>Cookies and browsing data to improve website functionality and user experience.</li>
          </ul>
        </div>

        {/* Section 3: How We Use Your Information */}
        <div className="mb-12">
          <h2 className="text-2xl font-semibold text-gray-800">How We Use Your Information</h2>
          <p className="mt-4 text-lg text-gray-600">
            The information we collect is used to:
          </p>
          <ul className="mt-4 list-disc pl-6 text-gray-600">
            <li>Process your orders and deliver products to you.</li>
            <li>Improve our website and customer service.</li>
            <li>Personalize your shopping experience and provide relevant recommendations.</li>
            <li>Communicate with you about your orders, promotions, and updates.</li>
          </ul>
        </div>

        {/* Section 4: How We Protect Your Information */}
        <div className="mb-12">
          <h2 className="text-2xl font-semibold text-gray-800">How We Protect Your Information</h2>
          <p className="mt-4 text-lg text-gray-600">
            We take your privacy seriously and implement various security measures to protect your personal information, including:
          </p>
          <ul className="mt-4 list-disc pl-6 text-gray-600">
            <li>Using secure payment gateways for transaction processing.</li>
            <li>Encrypting sensitive information like credit card details.</li>
            <li>Limiting access to your personal data to authorized personnel only.</li>
          </ul>
        </div>

        {/* Section 5: Sharing Your Information */}
        <div className="mb-12">
          <h2 className="text-2xl font-semibold text-gray-800">Sharing Your Information</h2>
          <p className="mt-4 text-lg text-gray-600">
            We do not sell, rent, or trade your personal information to third parties. However, we may share your information in the following cases:
          </p>
          <ul className="mt-4 list-disc pl-6 text-gray-600">
            <li>With trusted service providers who assist us in processing orders and improving our services.</li>
            <li>In response to legal obligations or when required by law.</li>
            <li>In the event of a business transfer, such as a merger or acquisition.</li>
          </ul>
        </div>

        {/* Section 6: Cookies and Tracking Technologies */}
        <div className="mb-12">
          <h2 className="text-2xl font-semibold text-gray-800">Cookies and Tracking Technologies</h2>
          <p className="mt-4 text-lg text-gray-600">
            We use cookies and other tracking technologies to enhance your experience on our website. These technologies help us:
          </p>
          <ul className="mt-4 list-disc pl-6 text-gray-600">
            <li>Remember your preferences and settings.</li>
            <li>Analyze website traffic and improve functionality.</li>
            <li>Show personalized ads and offers based on your interests.</li>
          </ul>
          <p className="mt-4 text-gray-600">
            You can control cookies through your browser settings. However, disabling cookies may affect some website functionalities.
          </p>
        </div>

        {/* Section 7: Your Rights */}
        <div className="mb-12">
          <h2 className="text-2xl font-semibold text-gray-800">Your Rights</h2>
          <p className="mt-4 text-lg text-gray-600">
            You have the following rights regarding your personal data:
          </p>
          <ul className="mt-4 list-disc pl-6 text-gray-600">
            <li>Access: You can request a copy of your personal data.</li>
            <li>Correction: You can update or correct your information.</li>
            <li>Deletion: You can request that we delete your personal data (subject to certain limitations).</li>
            <li>Opt-out: You can opt-out of marketing communications at any time.</li>
          </ul>
        </div>

        {/* Section 8: Changes to Privacy Policy */}
        <div className="mb-12">
          <h2 className="text-2xl font-semibold text-gray-800">Changes to This Privacy Policy</h2>
          <p className="mt-4 text-lg text-gray-600">
            We may update this Privacy Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. Any updates will be posted on this page with the updated effective date.
          </p>
        </div>

        {/* Section 9: Contact Us */}
        <div className="mb-12">
          <h2 className="text-2xl font-semibold text-gray-800">Contact Us</h2>
          <p className="mt-4 text-lg text-gray-600">
            If you have any questions or concerns about this Privacy Policy, please contact us at:
          </p>
          <p className="mt-4 text-lg text-gray-600">
            <strong>Email:</strong> support@yourstore.com
          </p>
          <p className="text-lg text-gray-600">
            <strong>Phone:</strong> +1-800-123-4567
          </p>
        </div>

        {/* Footer */}
        <div className="text-center">
          <p className="text-gray-600">
            © 2024 Your eCommerce Website | All Rights Reserved
          </p>
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
