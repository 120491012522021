import React from "react";
import AddItems from "../../../assets/images/addItems.png";
import { BiMinus, BiPlus } from "react-icons/bi";
function Item() {
  return (
    <>
      <div className="p-8 w-full">
        <div className="flex justify-between w-full">
          <div>
            <h1 className="text-4xl font-bold">Menu Item</h1>
            <p className="text-xl">
              Here is a quick overview about your restaurant’s Menu
            </p>
          </div>
          <div className="flex gap-2 h-fit self-end">
            <button className=" py-2 text-[#fa5b46] bg-red-100 rounded-xl font-semibold px-3">
              Delete
            </button>
            <button className="bg-[#003E00] py-2 text-white rounded-xl font-semibold px-3">
              Edit
            </button>
          </div>
        </div>
        <div className="mt-10 md:flex gap-5 w-full">
          <div className="md:w-1/2">
            <img src={AddItems} alt="addItems" className="w-fit md:w-full" />
          </div>
          <div className="flex flex-col gap-6 md:mt-0 mt-10 w-full">
            <div className="flex gap-5 w-full">
              <div className="flex flex-col w-full">
                <label className="font-semibold">Item Name</label>
                <p className="p-3 w-full text-gray-500">Snacks </p>
              </div>
              <div className="flex flex-col w-full">
                <label className="font-semibold">Item Number</label>
                <p className="p-3 w-full text-gray-500">001</p>
              </div>
            </div>
            <div>
              <label className="font-semibold">Item Description</label>
              <p className="p-3 w-full text-gray-500 ">Snacks</p>
            </div>
            <div className="flex gap-5 w-full">
              <div className="flex flex-col w-full">
                <label className="font-semibold">Quantity</label>
                <p className="p-3 w-full text-gray-500">500 gm</p>
              </div>
              <div className="flex flex-col w-full">
                <label className="font-semibold">No. of Units</label>
                <p className="p-3  w-full text-gray-500">100</p>
              </div>
            </div>
            <div className="flex gap-5 w-full">
              <div className="flex flex-col w-full">
                <label className="font-semibold">Choose Category</label>
                <p className="p-3 w-full text-gray-500">Snacks</p>
              </div>
              <div className="flex flex-col w-full">
                <label className="font-semibold">Items Status</label>
                <p className="p-3 w-full text-gray-500 ">Available</p>
              </div>
            </div>
          </div>
        </div>
        <div className="flex md:flex-row flex-col gap-5 w-full">
          <div className="flex md:flex-row flex-col gap-5 mt-6 w-full">
            <div className="flex flex-col w-full ">
              <label className="font-semibold">Name</label>
              <p className="p-3  w-full   text-gray-500">Wieghs</p>
            </div>
            <div className="flex flex-col w-full">
              <label className="font-semibold">Value</label>
              <p className="p-3  w-full  text-gray-500">500 gm</p>
            </div>
          </div>
          <div className="self-end flex gap-3 w-full">
            <div className="bg-blue-400 rounded-xl p-2">
              <BiPlus className="text-white" />
            </div>
            <div className="bg-gray-100 rounded-xl p-2">
              <BiMinus className="text-blue-400" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Item;
