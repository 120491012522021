import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import "./OrderSuccess.css";
import { useNavigate } from "react-router-dom";

const OrderSuccess = () => {
  const navigate = useNavigate();

  const handleContinueShopping = () => {
    navigate("/");
  };

  return (
    <>
      <Navbar />
      <div className="flex flex-col items-center justify-center h-[100vh] bg-green-50 ">
        <div className="flex justify-center items-center ">
          <div className="checkmark-container">
            <svg
              className="checkmark"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 52 52"
            >
              <circle
                className="checkmark__circle"
                cx="26"
                cy="26"
                r="25"
                fill="none"
              />
              <path
                className="checkmark__check"
                fill="none"
                d="M14.1 27.2l7.1 7.2 16.7-16.8"
              />
            </svg>
          </div>
        </div>

        <div className="text-center">
          <h1 className="text-4xl font-bold text-green-600 animate-fade-in">
            Order Success!
          </h1>
          <p className="mt-2 text-lg text-gray-600 animate-fade-in delay-200">
            Thank you for your order. It has been successfully placed.
          </p>
          <p className="text-gray-500 mt-1 animate-fade-in delay-400">
            We will send you an update when your order has shipped.
          </p>
        </div>

        <div className="mt-6">
          <button
            onClick={handleContinueShopping}
            className="px-6 py-3 bg-green-600 text-white font-semibold rounded-lg shadow-lg hover:bg-green-700 transition-all animate-fade-in delay-600"
          >
            Continue Shopping
          </button>
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </>
  );
};

export default OrderSuccess;
