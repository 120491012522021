import React from 'react';
import Logo from "../../assets/images/EURO FRESH MART.png";
import "../../index.css";
import { Link } from 'react-router-dom';

function Sidebar({ closeSidebar }) {
  return (
    <>
      <div className='md:px-4 px-2 md:py-8 py-5 h-[100vh] sticky top-0  z-10 '>
        <div className='flex justify-between gap-3  items-center'>
          <img src={Logo} alt="" />
          <button className='sm:hidden' onClick={closeSidebar}>
            &#10005; 
          </button>
        </div>

        <div className='flex md:mt-16 mt-8'>
          <ul className='flex flex-col gap-5 w-full'>
          <Link to="food-categories"> <li className='text-[#344054] font-medium text-lg p-3 hover:bg-[#003E00] hover:text-white rounded-md'>Food Categories</li></Link>
          <Link to="sub-categories"> <li className='text-[#344054] font-medium text-lg p-3 hover:bg-[#003E00] hover:text-white rounded-md'>Sub Categories</li></Link>
          <Link to="Orders"> <li className='text-[#344054] font-medium text-lg p-3 hover:bg-[#003E00] hover:text-white rounded-md'>Orders</li></Link>
          <Link to="inventory">  <li className='text-[#344054] font-medium text-lg p-3 hover:bg-[#003E00] hover:text-white rounded-md'>Inventory</li></Link>
          <Link to="banners"> <li className='text-[#344054] font-medium text-lg p-3 hover:bg-[#003E00] hover:text-white rounded-md'>Banner</li></Link>
          <Link to="coupons-code"> <li className='text-[#344054] font-medium text-lg p-3 hover:bg-[#003E00] hover:text-white rounded-md'>Coupons Code</li></Link>
          
          </ul>
        </div>
      </div>
    </>
  );
}

export default Sidebar;
