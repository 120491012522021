// import React, { useEffect, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { useSearchParams } from "react-router-dom";
// import ReactPaginate from "react-paginate";
// import img2 from "../../assets/images/shopping-cart-02.png";
// import { FaChevronUp, FaChevronDown } from "react-icons/fa";
// import CustomLink from "../CustomLink/CustomLink";
// import {
//   fetchInventories,
//   clearInventories,
// } from "../../redux/slices/inventorySlice";
// import { CiSearch } from "react-icons/ci";
// import { FaRegHeart, FaHeart } from "react-icons/fa";
// import "./OrderSearch.css";
// import {
//   addToWishlist,
//   removeFromWishlist,
// } from "../../redux/slices/wishlistSlice";
// import { addToCart, updateQuantity } from "../../redux/slices/cartSlice";
// import { toast } from "react-toastify";
// import { PiShoppingCartLight } from "react-icons/pi";
// import { Link } from "react-router-dom";
// import { TailSpin } from "react-loader-spinner";

// function OrderSearch() {
//   const [isSubCategoryOpen, setIsSubCategoryOpen] = useState(false);
//   const [selectedSubCategories, setSelectedSubCategories] = useState([]);
//   const cartItems = useSelector((state) => state.cart.cartItems);
//   const [isSizeOpen, setIsSizeOpen] = useState(false);
//   const [isBrandOpen, setIsBrandOpen] = useState(false);
//   const [isSaleOpen, setIsSaleOpen] = useState(false);
//   const [isPriceOpen, setIsPriceOpen] = useState(false);
//   const wishlistItems = useSelector((state) => state.wishlist.items);
//   const dispatch = useDispatch();
//   const { inventories, error } = useSelector((state) => state.inventory);
//   const [searchParams] = useSearchParams();
//   const paramId = searchParams.get("id");
//   const paramName = searchParams.get("name");
//   const [query, setQuery] = useState("");
//   const [count, setCount] = useState(1);
//   const [productCounts, setProductCounts] = useState({});

//   const [currentPage, setCurrentPage] = useState(0);
//   const itemsPerPage = 20;
//   const [loading, setLoading] = useState(true);
//   const [minPrice, setMinPrice] = useState("");
//   const [maxPrice, setMaxPrice] = useState("");
//   const [priceFilter, setPriceFilter] = useState({ min: null, max: null });

//   const uniqueSubCategories = [
//     ...new Set(inventories.map((item) => item?.subcategory?.name)),
//   ];

//   useEffect(() => {
//     if (paramId) {
//       setLoading(true);
//       setSelectedSubCategories([]); // Reset selected subcategories
//       dispatch(fetchInventories(paramId))
//         .then(() => {
//           setLoading(false);
//         })
//         .catch(() => {
//           setLoading(false);
//         });
//     }
//     return () => {
//       dispatch(clearInventories());
//     };
//   }, [dispatch, paramId]);

//   const handlePageClick = (data) => {
//     setCurrentPage(data.selected);
//     window.scrollTo(0, 0);
//   };
//   const handleSearch = (e) => {
//     setQuery(e.target.value);
//   };

//   const handleIncrement = (productId) => {
//     const updatedCount = (productCounts[productId] || 1) + 1;
//     setProductCounts((prevCounts) => ({
//       ...prevCounts,
//       [productId]: updatedCount,
//     }));
//     dispatch(updateQuantity({ productId, quantity: updatedCount }));
//   };

//   const handleDecrement = (productId) => {
//     const updatedCount = (productCounts[productId] || 1) - 1;
//     if (updatedCount < 1) return;
//     setProductCounts((prevCounts) => ({
//       ...prevCounts,
//       [productId]: updatedCount,
//     }));
//     dispatch(updateQuantity({ productId, quantity: updatedCount }));
//   };

//   const handleSubCategoryChange = (subcategoryId) => {
//     console.log(subcategoryId);
//     if (selectedSubCategories.includes(subcategoryId)) {
//       setSelectedSubCategories((prev) =>
//         prev.filter((id) => id !== subcategoryId)
//       );
//     } else {
//       setSelectedSubCategories((prev) => [...prev, subcategoryId]);
//     }
//   };

//   const handlePriceFilterSubmit = () => {
//     setPriceFilter({ min: parseFloat(minPrice), max: parseFloat(maxPrice) });
//   };

//   const filteredItems = inventories.filter((item) => {
//     const matchesQuery = item.name.toLowerCase().includes(query.toLowerCase());
//     const matchesSubcategory =
//       selectedSubCategories.length === 0 ||
//       selectedSubCategories.includes(item.subcategory?._id);
//     const withinPriceRange =
//       (!priceFilter.min || item.price >= priceFilter.min) &&
//       (!priceFilter.max || item.price <= priceFilter.max);

//     return matchesQuery && matchesSubcategory && withinPriceRange;
//   });

//   const toggleSubCategory = () => {
//     setIsSubCategoryOpen(!isSubCategoryOpen);
//   };

//   const toggleSize = () => {
//     setIsSizeOpen(!isSizeOpen);
//   };

//   const toggleBrand = () => {
//     setIsBrandOpen(!isBrandOpen);
//   };
//   const toggleSale = () => {
//     setIsSaleOpen(!isSaleOpen);
//   };

//   const togglePrice = () => {
//     setIsPriceOpen(!isPriceOpen);
//   };

//   const offset = currentPage * itemsPerPage;
//   const currentItems = filteredItems.slice(offset, offset + itemsPerPage);
//   const pageCount = Math.ceil(filteredItems.length / itemsPerPage);

//   if (loading) {
//     return (
//       <div className="flex justify-center items-center h-64">
//         <TailSpin
//           height="80"
//           width="80"
//           color="#ACE03A"
//           ariaLabel="tail-spin-loading"
//           radius="1"
//           visible={true}
//         />
//       </div>
//     );
//   }
//   if (inventories.length === 0) {
//     return (
//       <div className="text-center text-2xl font-semibold p-10">
//         No Items available!
//       </div>
//     );
//   }

//   const handleAddToWishlist = (e, item) => {
//     e.stopPropagation();
//     const isInWishlist = wishlistItems.some(
//       (wishlistItem) => wishlistItem._id === item._id
//     );
//     if (isInWishlist) {
//       dispatch(removeFromWishlist(item._id));
//       toast.error("Item Removed from Wishlist");
//     } else {
//       dispatch(addToWishlist(item));
//       toast.success("Item Added to Wishlist");
//     }
//   };

//   const handleAddToCart = (product) => {
//     if (product) {
//       dispatch(addToCart({ ...product, quantity: count }));
//       toast.success("Item Added To Cart");
//     }
//   };

//   return (
//     <div className="md:py-[24px] py-[12px] md:px-[32px] px-[16px]">
//       <div className="w-full flex md:flex-row flex-col gap-5">
//         <div className="md:w-[22%] h-[90%] border border-gray-200 p-4 flex flex-col gap-5  rounded-3xl shadow-lg overflow-hidden md:sticky md:top-24 relative top-0 md:max-h-[80vh]">
//           <div>
//             <h1 className="text-2xl MediumFont">Filters</h1>
//           </div>
//           <div className="flex flex-col gap-4 pr-2 overflow-y-auto max-h-[calc(100vh-120px)]">
//             <div
//               className="flex justify-between items-center cursor-pointer"
//               onClick={toggleSubCategory}
//             >
//               <p className="text-base MediumFont">Sub category</p>
//               {isSubCategoryOpen ? (
//                 <FaChevronUp size={18} />
//               ) : (
//                 <FaChevronDown size={18} />
//               )}
//             </div>
//             {isSubCategoryOpen && (
//               <div className="flex flex-col gap-2 ">
//                 {inventories
//                   .map((item) => item.subcategory)
//                   .filter(
//                     (subcategory, index, self) =>
//                       subcategory &&
//                       self.findIndex((s) => s?._id === subcategory._id) ===
//                         index
//                   )
//                   .map((subcategory) => (
//                     <div
//                       key={subcategory._id}
//                       className="flex justify-between items-center"
//                     >
//                       <p className="text-base NormalFont w-[80%]">
//                         {subcategory?.name}
//                       </p>
//                       <input
//                         type="checkbox"
//                         className="w-4 h-4  "
//                         checked={selectedSubCategories.includes(
//                           subcategory._id
//                         )}
//                         onChange={() =>
//                           handleSubCategoryChange(subcategory._id)
//                         }
//                       />
//                     </div>
//                   ))}
//               </div>
//             )}

//             <div
//               className="flex justify-between items-center cursor-pointer"
//               onClick={toggleSize}
//             >
//               <p className="text-base MediumFont">Quantity</p>
//               {isSizeOpen ? (
//                 <FaChevronUp size={18} />
//               ) : (
//                 <FaChevronDown size={18} />
//               )}
//             </div>
//             {isSizeOpen && (
//               <div className="flex flex-col gap-2">
//                 {["1 Kg", "2 Kg", "3 Kg", "4 Kg", "5 Kg"].map((size, index) => (
//                   <div
//                     key={index}
//                     className="flex justify-between items-center"
//                   >
//                     <p className="text-base NormalFont">{size}</p>
//                     <input type="checkbox" className="w-4 h-4" />
//                   </div>
//                 ))}
//               </div>
//             )}
//             <div
//               className="flex justify-between items-center cursor-pointer"
//               onClick={toggleBrand}
//             >
//               <p className="text-base MediumFont">Brand</p>
//               {isBrandOpen ? (
//                 <FaChevronUp size={18} />
//               ) : (
//                 <FaChevronDown size={18} />
//               )}
//             </div>
//             {isBrandOpen && (
//               <div className="flex flex-col gap-2">
//                 <p className="text-base NormalFont">Nestle</p>
//                 <p className="text-base NormalFont">Lay’s</p>
//                 <p className="text-base NormalFont">Amy's Kitchen</p>
//                 <p className="text-base NormalFont">Dannon</p>
//                 <p className="text-base NormalFont">General Mills</p>
//                 <p className="text-base NormalFont">Starbucks</p>
//                 <p className="text-base NormalFont">Healthy Choice</p>
//                 <p className="text-base NormalFont">McDonald's</p>
//               </div>
//             )}
//             <div
//               className="flex justify-between items-center cursor-pointer"
//               onClick={toggleSale}
//             >
//               <p className="text-base MediumFont">Sale</p>
//               {isSaleOpen ? (
//                 <FaChevronUp size={18} />
//               ) : (
//                 <FaChevronDown size={18} />
//               )}
//             </div>
//             {isSaleOpen && (
//               <div className="flex flex-col gap-2">
//                 <p className="text-base NormalFont">On Sale</p>
//                 <p className="text-base NormalFont">New Arrival</p>
//               </div>
//             )}

//             <div
//               className="flex justify-between items-center cursor-pointer"
//               onClick={() => setIsPriceOpen(!isPriceOpen)}
//             >
//               <p className="text-base MediumFont">Price</p>
//               {isPriceOpen ? (
//                 <FaChevronUp size={18} />
//               ) : (
//                 <FaChevronDown size={18} />
//               )}
//             </div>
//             {isPriceOpen && (
//               <div className="flex flex-col  gap-2 mt-2">
//                 <input
//                   placeholder="Min Price"
//                   value={minPrice}
//                   onChange={(e) => setMinPrice(e.target.value)}
//                   className="border p-2 rounded-md outline-none"
//                 />
//                 <input
//                   placeholder="Max Price"
//                   value={maxPrice}
//                   onChange={(e) => setMaxPrice(e.target.value)}
//                   className="border p-2 rounded-md outline-none"
//                 />
//                 <button
//                   onClick={handlePriceFilterSubmit}
//                   className="bg-[#ACE03A] text-[#040404] font-medium p-2 rounded-md mt-2"
//                 >
//                   Apply Price Filter
//                 </button>
//               </div>
//             )}
//           </div>
//         </div>

//         <div className="md:w-[78%]">
//           <div className="flex justify-between">
//             <p className="MediumFont text-2xl text-[#344054] capitalize">
//               {paramName}
//             </p>
//             <div className="hidden sm:flex  items-center justify-between border border-[#D0D5DD] rounded-2xl py-[8px] px-[12px]">
//               <input
//                 type="text"
//                 value={query}
//                 onChange={handleSearch}
//                 placeholder="Search products..."
//                 className="outline-none w-full"
//               />
//               <CiSearch size={24} />
//             </div>
//           </div>

//           <div className="mt-6 w-full">
//             <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-5 px-2 ">
//               {filteredItems.length > 0 ? (
//                 currentItems.map((item) => (
//                   <Link
//                         key={item._id}
//                         to={`/product/${item._id}`}
//                         className="flex flex-col  h-[220px] shadow-md rounded-2xl overflow-hidden "
//                       >
//                         <div className="relative flex items-center justify-center">
//                           <img
//                             src={item.imageUrl}
//                             alt=""
//                             className="bg-cover h-[120px] w-[140px]  rounded-b-xl "
//                           />
//                           <div
//                             className="absolute top-1 right-2 w-[24px] h-[24px] rounded-full flex items-center justify-center cursor-pointer"
//                             onClick={(e) => {
//                               e.preventDefault();
//                               e.stopPropagation();
//                               handleAddToWishlist(e, item);
//                             }}
//                           >
//                             {wishlistItems.some(
//                               (wishlistItem) => wishlistItem._id === item._id
//                             ) ? (
//                               <FaHeart className="text-red-500" size={28} />
//                             ) : (
//                               <FaRegHeart className="text-gray-300" size={28} />
//                             )}
//                           </div>
//                         </div>

//                         <div className="flex flex-col gap-2   px-4">
//                           <p className="text-xs  text-[#ADADAD] ">
//                             {/* {category.name} */}
//                           </p>
//                           <p className="font-semibold text-[16px] text-[#253D4E] ">
//                             {item.name.length > 17
//                               ? item.name.slice(0, 17) + "..."
//                               : item.name}
//                           </p>

//                           <div className="flex justify-between">
//                             <div className="flex gap-2">
//                               {item.retailPrice > 0 && (
//                                 <p className="text-[14px] text-[#ADADAD] line-through RegularFont">
//                                   € {item.retailPrice}
//                                 </p>
//                               )}
//                               <div></div>
//                               <p className="font-semibold text-lg my-0 text-[#ACE03A] ">
//                                 € {item.price}
//                               </p>
//                             </div>

//                             <div>
//                               <div className="flex items-center flex-col">
//                                 {cartItems.some(
//                                   (cartItems) => cartItems._id === item._id
//                                 ) ? (
//                                   <div className="flex items-center gap-2">
//                                     <button
//                                       onClick={(e) => {
//                                         e.preventDefault();
//                                         e.stopPropagation();
//                                         handleDecrement(item._id);
//                                       }}
//                                       className="bg-[#ACE03A] text-[#040404] w-6 h-6 rounded-full items-center justify-center"
//                                     >
//                                       -
//                                     </button>
//                                     <span className="text-[#253D4E] bg-[#F3F3F3] w-[26px] h-[22px] items-center flex justify-center">
//                                       {productCounts[item._id] || 1}
//                                     </span>
//                                     <button
//                                       onClick={(e) => {
//                                         e.preventDefault();
//                                         e.stopPropagation();
//                                         handleIncrement(item._id);
//                                       }}
//                                       className="bg-[#ACE03A] text-[#040404] w-6 h-6 rounded-full items-center justify-center"
//                                     >
//                                       +
//                                     </button>
//                                   </div>
//                                 ) : (
//                                   // Render "Add" button
//                                   <button
//                                     onClick={(e) => {
//                                       e.preventDefault();
//                                       e.stopPropagation();
//                                       handleAddToCart(item);
//                                     }}
//                                     className="flex items-center justify-between py-1 px-1 w-[75px] bg-[#ACE03A] rounded-md"
//                                   >
//                                     <PiShoppingCartLight
//                                       size={18}
//                                       color="#040404"
//                                     />
//                                     <p className="text-[#040404] text-[14px] font-medium">
//                                       Add
//                                     </p>
//                                   </button>
//                                 )}
//                               </div>
//                             </div>
//                           </div>
//                         </div>
//                       </Link>
//                 ))
//               ) : (
//                 <p>No items available.</p>
//               )}
//             </div>

//             <div className="mt-10 flex justify-center">
//               <ReactPaginate
//                 previousLabel={"Previous"}
//                 nextLabel={"Next"}
//                 pageCount={pageCount}
//                 onPageChange={handlePageClick}
//                 containerClassName={"pagination"}
//                 activeClassName={"active"}
//                 previousClassName={"page-item"}
//                 nextClassName={"page-item"}
//                 breakClassName={"page-item"}
//                 pageClassName={"page-item"}
//                 disabledClassName={"disabled"}
//               />
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default OrderSearch;

//new code for pagination data limit
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import ReactPaginate from "react-paginate";
import img2 from "../../assets/images/shopping-cart-02.png";
import { FaChevronUp, FaChevronDown } from "react-icons/fa";
import CustomLink from "../CustomLink/CustomLink";
import {
  fetchInventories,
  clearInventories,
} from "../../redux/slices/inventorySlice";
import { CiSearch } from "react-icons/ci";
import { FaRegHeart, FaHeart } from "react-icons/fa";
import "./OrderSearch.css";
import {
  addToWishlist,
  removeFromWishlist,
} from "../../redux/slices/wishlistSlice";
import { addToCart, updateQuantity } from "../../redux/slices/cartSlice";
import { toast } from "react-toastify";
import { PiShoppingCartLight } from "react-icons/pi";
import { Link } from "react-router-dom";
import { TailSpin } from "react-loader-spinner";

function OrderSearch() {
  const [isSubCategoryOpen, setIsSubCategoryOpen] = useState(false);
  const [selectedSubCategories, setSelectedSubCategories] = useState([]);
  const cartItems = useSelector((state) => state.cart.cartItems);
  const [isSizeOpen, setIsSizeOpen] = useState(false);
  const [isBrandOpen, setIsBrandOpen] = useState(false);
  const [isSaleOpen, setIsSaleOpen] = useState(false);
  const [isPriceOpen, setIsPriceOpen] = useState(false);
  const wishlistItems = useSelector((state) => state.wishlist.items);
  const dispatch = useDispatch();
  const { inventories: { inventoryItems, totalPages } = {}, error } =
    useSelector((state) => state.inventory);
  const [searchParams] = useSearchParams();
  const paramId = searchParams.get("id");
  const paramName = searchParams.get("name");
  const [query, setQuery] = useState("");
  const [count, setCount] = useState(1);
  const [productCounts, setProductCounts] = useState({});

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20;
  const [loading, setLoading] = useState(true);
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");
  const [priceFilter, setPriceFilter] = useState({ min: null, max: null });

  useEffect(() => {
    if (paramId) {
      setLoading(true);
      setSelectedSubCategories([]); // Reset selected subcategories
      dispatch(
        fetchInventories({
          categoryId: paramId,
          page: currentPage,
          limit: itemsPerPage,
        })
      )
        .then(() => {
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
    return () => {
      dispatch(clearInventories());
    };
  }, [dispatch, paramId, currentPage, itemsPerPage]);

  // const handlePageClick = (data) => {
  //   setCurrentPage(data.selected + 1); // Adjust for 0-indexed `selected`
  //   window.scrollTo(0, 0);
  // };
  const handlePageClick = (data) => {
    const selectedPage = data.selected + 1;
    setCurrentPage(selectedPage);
    window.scrollTo(0, 0);
  };

  const handleSearch = (e) => {
    setQuery(e.target.value);
  };

  const handleIncrement = (productId) => {
    const updatedCount = (productCounts[productId] || 1) + 1;
    setProductCounts((prevCounts) => ({
      ...prevCounts,
      [productId]: updatedCount,
    }));
    dispatch(updateQuantity({ productId, quantity: updatedCount }));
  };

  const handleDecrement = (productId) => {
    const updatedCount = (productCounts[productId] || 1) - 1;
    if (updatedCount < 1) return;
    setProductCounts((prevCounts) => ({
      ...prevCounts,
      [productId]: updatedCount,
    }));
    dispatch(updateQuantity({ productId, quantity: updatedCount }));
  };

  const handleSubCategoryChange = (subcategoryId) => {
    console.log(subcategoryId);
    if (selectedSubCategories.includes(subcategoryId)) {
      setSelectedSubCategories((prev) =>
        prev.filter((id) => id !== subcategoryId)
      );
    } else {
      setSelectedSubCategories((prev) => [...prev, subcategoryId]);
    }
  };

  const handlePriceFilterSubmit = () => {
    setPriceFilter({ min: parseFloat(minPrice), max: parseFloat(maxPrice) });
  };

  // const filteredItems = inventories.filter((item) => {
  //   const matchesQuery = item.name.toLowerCase().includes(query.toLowerCase());
  //   const matchesSubcategory =
  //     selectedSubCategories.length === 0 ||
  //     selectedSubCategories.includes(item.subcategory?._id);
  //   const withinPriceRange =
  //     (!priceFilter.min || item.price >= priceFilter.min) &&
  //     (!priceFilter.max || item.price <= priceFilter.max);

  //   return matchesQuery && matchesSubcategory && withinPriceRange;
  // });
  const filteredItems = Array.isArray(inventoryItems)
    ? inventoryItems.filter((item) => {
        const matchesQuery = item.name
          .toLowerCase()
          .includes(query.toLowerCase());
        const matchesSubcategory =
          selectedSubCategories.length === 0 ||
          selectedSubCategories.includes(item.subcategory?._id);
        const withinPriceRange =
          (!priceFilter.min || item.price >= priceFilter.min) &&
          (!priceFilter.max || item.price <= priceFilter.max);

        return matchesQuery && matchesSubcategory && withinPriceRange;
      })
    : [];

  const toggleSubCategory = () => {
    setIsSubCategoryOpen(!isSubCategoryOpen);
  };

  const toggleSize = () => {
    setIsSizeOpen(!isSizeOpen);
  };

  const toggleBrand = () => {
    setIsBrandOpen(!isBrandOpen);
  };
  const toggleSale = () => {
    setIsSaleOpen(!isSaleOpen);
  };

  const togglePrice = () => {
    setIsPriceOpen(!isPriceOpen);
  };

  const offset = (currentPage - 1) * itemsPerPage;
  const currentItems = filteredItems.slice(offset, offset + itemsPerPage);
  // const currentItems = filteredItems.slice(offset, offset + itemsPerPage);
  // const pageCount = Math.ceil(filteredItems.length / itemsPerPage);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <TailSpin
          height="80"
          width="80"
          color="#ACE03A"
          ariaLabel="tail-spin-loading"
          radius="1"
          visible={true}
        />
      </div>
    );
  }
  if (inventoryItems?.length === 0) {
    return (
      <div className="text-center text-2xl font-semibold p-10">
        No Items available!
      </div>
    );
  }

  const handleAddToWishlist = (e, item) => {
    e.stopPropagation();
    const isInWishlist = wishlistItems.some(
      (wishlistItem) => wishlistItem._id === item._id
    );
    if (isInWishlist) {
      dispatch(removeFromWishlist(item._id));
      toast.error("Item Removed from Wishlist");
    } else {
      dispatch(addToWishlist(item));
      toast.success("Item Added to Wishlist");
    }
  };

  const handleAddToCart = (product) => {
    if (product) {
      dispatch(addToCart({ ...product, quantity: count }));
      toast.success("Item Added To Cart");
    }
  };

  return (
    <div className="md:py-[24px] py-[12px] md:px-[32px] px-[16px]">
      <div className="w-full flex md:flex-row flex-col gap-5">
        <div className="md:w-[22%] h-[90%] border border-gray-200 p-4 flex flex-col gap-5  rounded-3xl shadow-lg overflow-hidden md:sticky md:top-24 relative top-0 md:max-h-[80vh]">
          <div>
            <h1 className="text-2xl MediumFont">Filters</h1>
          </div>
          <div className="flex flex-col gap-4 pr-2 overflow-y-auto max-h-[calc(100vh-120px)]">
            <div
              className="flex justify-between items-center cursor-pointer"
              onClick={toggleSubCategory}
            >
              <p className="text-base MediumFont">Sub category</p>
              {isSubCategoryOpen ? (
                <FaChevronUp size={18} />
              ) : (
                <FaChevronDown size={18} />
              )}
            </div>
            {isSubCategoryOpen && (
              <div className="flex flex-col gap-2 ">
                {inventoryItems
                  ?.map((item) => item.subcategory)
                  .filter(
                    (subcategory, index, self) =>
                      subcategory &&
                      self.findIndex((s) => s?._id === subcategory._id) ===
                        index
                  )
                  .map((subcategory) => (
                    <div
                      key={subcategory._id}
                      className="flex justify-between items-center"
                    >
                      <p className="text-base NormalFont w-[80%]">
                        {subcategory?.name}
                      </p>
                      <input
                        type="checkbox"
                        className="w-4 h-4  "
                        checked={selectedSubCategories.includes(
                          subcategory._id
                        )}
                        onChange={() =>
                          handleSubCategoryChange(subcategory._id)
                        }
                      />
                    </div>
                  ))}
              </div>
            )}

            <div
              className="flex justify-between items-center cursor-pointer"
              onClick={toggleSize}
            >
              <p className="text-base MediumFont">Quantity</p>
              {isSizeOpen ? (
                <FaChevronUp size={18} />
              ) : (
                <FaChevronDown size={18} />
              )}
            </div>
            {isSizeOpen && (
              <div className="flex flex-col gap-2">
                {["1 Kg", "2 Kg", "3 Kg", "4 Kg", "5 Kg"].map((size, index) => (
                  <div
                    key={index}
                    className="flex justify-between items-center"
                  >
                    <p className="text-base NormalFont">{size}</p>
                    <input type="checkbox" className="w-4 h-4" />
                  </div>
                ))}
              </div>
            )}
           
            <div
              className="flex justify-between items-center cursor-pointer"
              onClick={toggleSale}
            >
              <p className="text-base MediumFont">Sale</p>
              {isSaleOpen ? (
                <FaChevronUp size={18} />
              ) : (
                <FaChevronDown size={18} />
              )}
            </div>
            {isSaleOpen && (
              <div className="flex flex-col gap-2">
                <p className="text-base NormalFont">On Sale</p>
                <p className="text-base NormalFont">New Arrival</p>
              </div>
            )}

            <div
              className="flex justify-between items-center cursor-pointer"
              onClick={() => setIsPriceOpen(!isPriceOpen)}
            >
              <p className="text-base MediumFont">Price</p>
              {isPriceOpen ? (
                <FaChevronUp size={18} />
              ) : (
                <FaChevronDown size={18} />
              )}
            </div>
            {isPriceOpen && (
              <div className="flex flex-col  gap-2 mt-2">
                <input
                  placeholder="Min Price"
                  value={minPrice}
                  onChange={(e) => setMinPrice(e.target.value)}
                  className="border p-2 rounded-md outline-none"
                />
                <input
                  placeholder="Max Price"
                  value={maxPrice}
                  onChange={(e) => setMaxPrice(e.target.value)}
                  className="border p-2 rounded-md outline-none"
                />
                <button
                  onClick={handlePriceFilterSubmit}
                  className="bg-[#ACE03A] text-[#040404] font-medium p-2 rounded-md mt-2"
                >
                  Apply Price Filter
                </button>
              </div>
            )}
          </div>
        </div>

        <div className="md:w-[78%]">
          <div className="flex justify-between">
            <p className="MediumFont text-2xl text-[#344054] capitalize">
              {paramName}
            </p>
            <div className="hidden sm:flex  items-center justify-between border border-[#D0D5DD] rounded-2xl py-[8px] px-[12px]">
              <input
                type="text"
                value={query}
                onChange={handleSearch}
                placeholder="Search products..."
                className="outline-none w-full"
              />
              <CiSearch size={24} />
            </div>
          </div>

          <div className="mt-6 w-full">
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-5 px-2 ">
              {filteredItems.length > 0 ? (
                filteredItems.map((item) => (
                  <Link
                    key={item._id}
                    to={`/product/${item._id}`}
                    className="flex flex-col  h-[210px]  md:h-[230px] lg:h-[240px] xl:h-[230px] shadow-md rounded-2xl overflow-hidden "
                  >
                    <div className="relative flex items-center justify-center">
                      <img
                        src={item.imageUrl}
                        alt=""
                        className="bg-cover h-[120px] w-[140px]  rounded-b-xl "
                      />
                      <div
                        className="absolute top-1 right-2 w-[24px] h-[24px] rounded-full flex items-center justify-center cursor-pointer"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          handleAddToWishlist(e, item);
                        }}
                      >
                        {wishlistItems.some(
                          (wishlistItem) => wishlistItem._id === item._id
                        ) ? (
                          <FaHeart className="text-red-500" size={28} />
                        ) : (
                          <FaRegHeart className="text-gray-300" size={28} />
                        )}
                      </div>
                    </div>

                    <div className="flex flex-col gap-2   px-4">
                      <p className="text-xs  text-[#ADADAD] ">
                        {/* {category.name} */}
                      </p>
                      <p className="font-semibold text-[16px] text-[#253D4E] ">
                        {item.name.length > 30
                          ? item.name.slice(0, 30) + "..."
                          : item.name}
                      </p>

                      <div className="flex justify-between">
                        <div className="flex gap-2">
                          {item.retailPrice > 0 && (
                            <p className="text-[14px] text-[#ADADAD] line-through RegularFont">
                              € {item.retailPrice}
                            </p>
                          )}
                          <div></div>
                          <p className="font-semibold text-lg my-0 text-[#ACE03A] ">
                            € {item.price}
                          </p>
                        </div>

                        <div>
                          <div className="flex items-center flex-col">
                            {cartItems.some(
                              (cartItems) => cartItems._id === item._id
                            ) ? (
                              <div className="flex items-center gap-2">
                                <button
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    handleDecrement(item._id);
                                  }}
                                  className="bg-[#ACE03A] text-[#040404] w-6 h-6 rounded-full items-center justify-center"
                                >
                                  -
                                </button>
                                <span className="text-[#253D4E] bg-[#F3F3F3] w-[26px] h-[22px] items-center flex justify-center">
                                  {productCounts[item._id] || 1}
                                </span>
                                <button
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    handleIncrement(item._id);
                                  }}
                                  className="bg-[#ACE03A] text-[#040404] w-6 h-6 rounded-full items-center justify-center"
                                >
                                  +
                                </button>
                              </div>
                            ) : (
                              // Render "Add" button
                              <button
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  handleAddToCart(item);
                                }}
                                className="flex items-center justify-between py-1 px-1 w-[75px] bg-[#ACE03A] rounded-md"
                              >
                                <PiShoppingCartLight
                                  size={18}
                                  color="#040404"
                                />
                                <p className="text-[#040404] text-[14px] font-medium">
                                  Add
                                </p>
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                ))
              ) : (
                <p>No items available.</p>
              )}
            </div>

            <div className="mt-10 flex justify-center">
              <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next"}
                pageCount={totalPages}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                activeClassName={"active"}
                previousClassName={"page-item"}
                nextClassName={"page-item"}
                breakClassName={"page-item"}
                pageClassName={"page-item"}
                disabledClassName={"disabled"}
                forcePage={currentPage - 1}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrderSearch;
