import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { fetchBannersByType } from "../../redux/slices/bannerSlice";
import "../../../src/index.css";
import { Link } from "react-router-dom";
  

function Landingpage() {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.categories);
  const data = useSelector((state) => state.banner);
  const { banners} = data;

  useEffect(() => {
    dispatch(fetchBannersByType("Middle Banner"));
  }, [dispatch]);

  const settings = {
    infinite: false,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <div className="md:py-[24px] py-[12px] md:px-[32px] px-[16px] mt-10"> 
          <Slider {...settings}>
            {banners?.middleBanner[0]?.map((banner, index) => (
              <Link
              key={index}
              className="w-full cursor-pointer"
              to={`/orderPlacement?name=${state?.items[0]?.name}&id=${state?.items[0]?._id}`}
            >
              <div className="w-full">
                <img src={banner.image} alt={banner.title} className="w-full" />
              </div>
            </Link>
            ))}
          </Slider>
      </div>
    </>
  );
}

export default Landingpage;
