import React, { useState, useEffect } from "react";
import { ENDPOINTS } from "../../api/apiRoutes";
import flagicon from "../../assets/images/flagicon.png";
import xclose from "../../assets/images/x-close.png";
import "../../index.css";
import API from "../../api/apiService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function EditSubCategories({ handleCloseModal, category }) {
  const [categoryName, setCategoryName] = useState(category.name || "");
  const [isLoading, setIsLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [categoryId, setCategoryId] = useState(
    category.parentCategory._id || ""
  );

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await API.get(ENDPOINTS.GET_CATEGORIES);
        setCategories(response.categories || []);
      } catch (error) {
        console.error("Error fetching categories:", error);
        setCategories([]);
      }
    };
    fetchCategories();
  }, []);

  const handleCategoryNameChange = (e) => {
    setCategoryName(e.target.value);
  };

  const handleEditCategory = async () => {
    if (!categoryName || !categoryId) {
      toast.error("Please enter a subcategory name and select a category.");
      return;
    }

    setIsLoading(true);
    try {
      const payload = {
        name: categoryName,
        parentCategory: categoryId,
      };

      const token = localStorage.getItem("token");

      await API.put(
        ENDPOINTS.UPDATE_SUBCATEGORIES.replace(":id", category._id),
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      toast.success("Subcategory updated successfully!");

      setTimeout(() => {
        handleCloseModal();
      }, 2000);
    } catch (error) {
      console.error("Error updating subcategory:", error);
      toast.error("An error occurred. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <ToastContainer autoClose={2000} />
      <div className="fixed inset-0 bg-gray-300 bg-opacity-50 backdrop-blur-sm z-40"></div>

      <div className="fixed inset-0 flex items-center justify-center z-50">
        <div className="md:p-6 p-4 border-2 rounded-md md:w-[30%] w-full bg-white">
          <div className="flex justify-between">
            <div className="p-2 border border-gray-300 rounded-md">
              <img src={flagicon} alt="Flag Icon" />
            </div>
            <div>
              <img
                src={xclose}
                alt="Close Icon"
                onClick={handleCloseModal}
                className="cursor-pointer"
              />
            </div>
          </div>

          <div className="mt-6 flex flex-col gap-5">
            <div>
              <h1 className="text-[#101828] font-semibold text-[18px]">
                Edit Sub Category
              </h1>
            </div>

            <div className="flex flex-col gap-2">
              <p className="text-[#344054] text-sm">Name</p>
              <input
                type="text"
                placeholder="Enter Subcategory Name"
                className="border border-gray-300 outline-none p-2 rounded-md"
                value={categoryName}
                onChange={handleCategoryNameChange}
              />
            </div>

            <div className="flex flex-col w-full">
              <label className="font-semibold">Category</label>
              <select
                className="p-3 rounded-xl border w-full outline-none"
                value={categoryId}
                onChange={(e) => setCategoryId(e.target.value)}
              >
                <option value="" disabled>
                  Select Category
                </option>
                {categories.length > 0 ? (
                  categories.map((category) => (
                    <option key={category._id} value={category._id}>
                      {category.name}
                    </option>
                  ))
                ) : (
                  <option value="" disabled>
                    No categories available
                  </option>
                )}
              </select>
            </div>

            <div className="flex justify-end">
              <button
                onClick={handleEditCategory}
                className="bg-[#003E00] text-white p-2 rounded-md"
                disabled={isLoading}
              >
                {isLoading ? "Saving..." : "Save Changes"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditSubCategories;
