import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

const AddressModal = ({ isOpen, onClose, onSave, address }) => {
  if (!isOpen) return null;


  const validationSchema = Yup.object().shape({
    street: Yup.string().required('Street Address is required'),
    state: Yup.string().required('State is required'),
    city: Yup.string().required('City is required'),
    postalCode: Yup.string().required('Postal Code is required'),
  });

  return (
    <div className='fixed inset-0 flex items-center justify-center bg-black bg-opacity-50'>
      <div className='bg-white p-6 rounded-lg shadow-lg md:w-[40%] flex flex-col'>
        <h2 className='text-xl font-bold mb-4'>Edit Address</h2>
        <Formik
          initialValues={address}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            onSave(values);
            onClose();
          }}
        >
          {({ values, handleChange, handleSubmit }) => (
            <Form>
              <div className="flex gap-4 w-full">
              
              
              </div>

            
              <div className="flex gap-4 w-full">
               
                <div className='mb-4 w-full'>
                  <label className='block text-sm font-medium mb-1'>Street Address</label>
                  <Field
                    type='text'
                    name='street'
                    className='border border-gray-300 p-2 rounded-lg w-full outline-none'
                  />
                  <ErrorMessage name="street" component="div" className="text-red-500 text-sm mt-1" />
                </div>

               
                <div className='mb-4 w-full'>
                  <label className='block text-sm font-medium mb-1'>State</label>
                  <Field
                    type='text'
                    name='state'
                    className='border border-gray-300 p-2 rounded-lg w-full outline-none'
                  />
                  <ErrorMessage name="state" component="div" className="text-red-500 text-sm mt-1" />
                </div>
              </div>

              <div className="flex gap-4">
                {/* City */}
                <div className='mb-4 w-full'>
                  <label className='block text-sm font-medium mb-1'>City</label>
                  <Field
                    type='text'
                    name='city'
                    className='border border-gray-300 p-2 rounded-lg w-full outline-none'
                  />
                  <ErrorMessage name="city" component="div" className="text-red-500 text-sm mt-1" />
                </div>

            
                <div className='mb-4 w-full'>
                  <label className='block text-sm font-medium mb-1'>Postal Code</label>
                  <Field
                    type='text'
                    name='postalCode'
                    className='border border-gray-300 p-2 rounded-lg w-full outline-none'
                  />
                  <ErrorMessage name="postalCode" component="div" className="text-red-500 text-sm mt-1" />
                </div>
              </div>

            
              <div className='flex justify-end gap-4'>
                <button
                  type="button"
                  onClick={onClose}
                  className=' text-black border border-gray-200 py-2 px-4 rounded-lg'
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className='bg-[#004D00] text-white py-2 px-4 rounded-lg'
                >
                  Save
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default AddressModal;
