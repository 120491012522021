// src/pages/QA.jsx
import React, { useState } from "react";

const faqData = [
  {
    question: "What is Eurofresh Mart?",
    answer:
      "Eurofresh Mart is an online marketplace that offers fresh groceries, organic products, and more delivered directly to your doorstep.",
  },
  {
    question: "What are the delivery timings?",
    answer:
      "Our delivery timings are between 8:00 AM and 8:00 PM. You can select your preferred slot during checkout.",
  },
  {
    question: "Is there a minimum order value for free delivery?",
    answer:
      "Yes, orders above €0 qualify for free delivery. For orders below €50, a delivery fee of €5 is applicable.",
  },
  {
    question: "Can I return or exchange a product?",
    answer:
      "Yes, we accept returns or exchanges within 7 days of delivery for items in their original packaging.",
  },
  {
    question: "How can I contact customer support?",
    answer:
      "You can reach our customer support team via email at support@eurofreshmart.com or call us at 1-800-123-456.",
  },
];

const QA = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleAnswer = (index) => {
    setOpenIndex(index === openIndex ? null : index);
  };

  return (
    <div className="min-h-screen bg-gray-100 py-8 px-4">
      <div className="max-w-3xl mx-auto bg-white shadow-md rounded-md p-6">
        <h1 className="text-2xl font-bold text-center mb-6">
          Frequently Asked Questions (FAQ)
        </h1>
        <ul className="space-y-4">
          {faqData.map((item, index) => (
            <li
              key={index}
              className="border-b border-gray-200 pb-4"
            >
              <div
                className="flex justify-between items-center cursor-pointer"
                onClick={() => toggleAnswer(index)}
              >
                <h2 className="text-lg font-semibold">
                  {item.question}
                </h2>
                <button className="text-3xl">
                  {openIndex === index ? "-" : "+"}
                </button>
              </div>
              {openIndex === index && (
                <p className="mt-2 text-gray-600">{item.answer}</p>
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default QA;
