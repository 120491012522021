// src/pages/Refund.jsx
import React, { useEffect } from "react";

const Refund = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const handleSubmit = (e) => {
    e.preventDefault();
    alert("Your refund request has been submitted successfully!");
  };

  return (
    <div className="min-h-screen bg-gray-100 py-8 px-4">
      <div className="max-w-4xl mx-auto bg-white shadow-md rounded-md p-6">
        <h1 className="text-2xl font-bold text-center mb-6">Refund Policy</h1>

        {/* Refund Policy Section */}
        <div className="mb-8">
          <h2 className="text-lg font-semibold mb-4">Refund Policy Overview</h2>
          <p className="text-gray-600 leading-6">
            We strive to provide excellent customer service. If you are not
            satisfied with your purchase, you may request a refund under the
            following conditions:
          </p>
          <ul className="list-disc list-inside mt-4 space-y-2 text-gray-600">
            <li>Refund requests must be made within 14 days of purchase.</li>
            <li>
              Items must be in their original condition and packaging, with no
              signs of use or damage.
            </li>
            <li>
              Digital products are non-refundable unless there is a technical
              issue.
            </li>
            <li>Shipping fees are non-refundable.</li>
          </ul>
        </div>

        {/* Refund Request Form */}
        <div>
          <h2 className="text-lg font-semibold mb-4">Request a Refund</h2>
          <form onSubmit={handleSubmit}>
            <div className="space-y-4">
              <input
                type="text"
                placeholder="Full Name"
                className="w-full border rounded-md px-4 py-2 focus:outline-none focus:ring focus:ring-blue-200"
                required
              />
              <input
                type="email"
                placeholder="Email Address"
                className="w-full border rounded-md px-4 py-2 focus:outline-none focus:ring focus:ring-blue-200"
                required
              />
              <input
                type="text"
                placeholder="Order Number"
                className="w-full border rounded-md px-4 py-2 focus:outline-none focus:ring focus:ring-blue-200"
                required
              />
              <textarea
                placeholder="Reason for Refund"
                rows="4"
                className="w-full border rounded-md px-4 py-2 focus:outline-none focus:ring focus:ring-blue-200"
                required
              ></textarea>
              <div>
                <label className="block text-gray-600 mb-2">
                  Attach a file (Optional)
                </label>
                <input
                  type="file"
                  className="w-full border rounded-md px-4 py-2 focus:outline-none focus:ring focus:ring-blue-200"
                />
              </div>
            </div>
            <button
              type="submit"
              className="w-full mt-6 bg-blue-500 text-white font-semibold py-2 rounded-md hover:bg-blue-600 transition"
            >
              Submit Refund Request
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Refund;
